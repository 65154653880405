/**
 * @fileoverview gRPC-Web generated client stub for InkCapture
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.6
// source: icws_proto/icws_api_gateway/auth.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as icws_proto_icws_api_gateway_auth_change_document_owner_pb from '../../icws_proto/icws_api_gateway/auth/change_document_owner_pb';
import * as icws_proto_icws_api_gateway_auth_change_folder_owner_pb from '../../icws_proto/icws_api_gateway/auth/change_folder_owner_pb';
import * as icws_proto_icws_api_gateway_auth_set_document_permissions_pb from '../../icws_proto/icws_api_gateway/auth/set_document_permissions_pb';
import * as icws_proto_icws_api_gateway_auth_set_folder_permissions_pb from '../../icws_proto/icws_api_gateway/auth/set_folder_permissions_pb';


export class AuthClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSetDocumentPermissions = new grpcWeb.MethodDescriptor(
    '/InkCapture.Auth/SetDocumentPermissions',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsRequest,
    icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsResponse,
    (request: icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsResponse.deserializeBinary
  );

  setDocumentPermissions(
    request: icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsResponse>;

  setDocumentPermissions(
    request: icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsResponse>;

  setDocumentPermissions(
    request: icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_set_document_permissions_pb.SetDocumentPermissionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Auth/SetDocumentPermissions',
        request,
        metadata || {},
        this.methodDescriptorSetDocumentPermissions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Auth/SetDocumentPermissions',
    request,
    metadata || {},
    this.methodDescriptorSetDocumentPermissions);
  }

  methodDescriptorSetFolderPermissions = new grpcWeb.MethodDescriptor(
    '/InkCapture.Auth/SetFolderPermissions',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsRequest,
    icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsResponse,
    (request: icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsResponse.deserializeBinary
  );

  setFolderPermissions(
    request: icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsResponse>;

  setFolderPermissions(
    request: icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsResponse>;

  setFolderPermissions(
    request: icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_set_folder_permissions_pb.SetFolderPermissionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Auth/SetFolderPermissions',
        request,
        metadata || {},
        this.methodDescriptorSetFolderPermissions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Auth/SetFolderPermissions',
    request,
    metadata || {},
    this.methodDescriptorSetFolderPermissions);
  }

  methodDescriptorChangeDocumentOwner = new grpcWeb.MethodDescriptor(
    '/InkCapture.Auth/ChangeDocumentOwner',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerRequest,
    icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerResponse,
    (request: icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerResponse.deserializeBinary
  );

  changeDocumentOwner(
    request: icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerResponse>;

  changeDocumentOwner(
    request: icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerResponse>;

  changeDocumentOwner(
    request: icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_change_document_owner_pb.ChangeDocumentOwnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Auth/ChangeDocumentOwner',
        request,
        metadata || {},
        this.methodDescriptorChangeDocumentOwner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Auth/ChangeDocumentOwner',
    request,
    metadata || {},
    this.methodDescriptorChangeDocumentOwner);
  }

  methodDescriptorChangeFolderOwner = new grpcWeb.MethodDescriptor(
    '/InkCapture.Auth/ChangeFolderOwner',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerRequest,
    icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerResponse,
    (request: icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerResponse.deserializeBinary
  );

  changeFolderOwner(
    request: icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerResponse>;

  changeFolderOwner(
    request: icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerResponse>;

  changeFolderOwner(
    request: icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_auth_change_folder_owner_pb.ChangeFolderOwnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Auth/ChangeFolderOwner',
        request,
        metadata || {},
        this.methodDescriptorChangeFolderOwner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Auth/ChangeFolderOwner',
    request,
    metadata || {},
    this.methodDescriptorChangeFolderOwner);
  }

}

