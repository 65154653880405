import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[content-scrollbar]",
})
export class ContentScrollbarDirective implements OnInit {
  @Input() scrollbar: string;
  @Input() allowAllScreens: boolean | string;

  hostElement: HTMLElement;

  constructor(public elementRef: ElementRef) {}

  ngOnInit() {
    this.hostElement = this.elementRef.nativeElement;
    if (this.hostElement && this.hostElement.tagName && this.hostElement.tagName == "ION-CONTENT") {
      let el = document.createElement("style");
      el.innerText = this.scrollbar || this.getCustomStyle();
      this.hostElement.shadowRoot.appendChild(el);
    }
  }

  getCustomStyle() {
    if (this.allowAllScreens === true || this.allowAllScreens === "true") {
      return `
      ::-webkit-scrollbar {
        width: 6px !important;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: #a1a1a1;
        border-radius: 6px;
      }
      ::-webkit-scrollbar-track:hover {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #8e8e8e;
      }
      .inner-scroll {
        scrollbar-width: thin;
      }`;
    } else {
      return `
      @media(pointer: fine) {
        ::-webkit-scrollbar {
          width: 6px !important;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background: #a1a1a1;
          border-radius: 6px;
        }
        //::-webkit-scrollbar-track:hover {
        //  background: transparent;
        //}
        //::-webkit-scrollbar-thumb:hover {
        //  background: #8e8e8e;
        //}
        //.inner-scroll {
        //  scrollbar-width: thin;
        //}
      }`;
    }
  }
}
