// source: icws_proto/icws_api_gateway/storage/base.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var icws_proto_icws_api_gateway_types_pb = require('../../../icws_proto/icws_api_gateway/types_pb.js');
goog.object.extend(proto, icws_proto_icws_api_gateway_types_pb);
var icws_proto_icws_api_gateway_storage_storage_types_pb = require('../../../icws_proto/icws_api_gateway/storage/storage_types_pb.js');
goog.object.extend(proto, icws_proto_icws_api_gateway_storage_storage_types_pb);
var icws_proto_icws_api_gateway_auth_base_pb = require('../../../icws_proto/icws_api_gateway/auth/base_pb.js');
goog.object.extend(proto, icws_proto_icws_api_gateway_auth_base_pb);
goog.exportSymbol('proto.InkCapture.Block', null, global);
goog.exportSymbol('proto.InkCapture.Document', null, global);
goog.exportSymbol('proto.InkCapture.DocumentLocation', null, global);
goog.exportSymbol('proto.InkCapture.Folder', null, global);
goog.exportSymbol('proto.InkCapture.Line', null, global);
goog.exportSymbol('proto.InkCapture.Point', null, global);
goog.exportSymbol('proto.InkCapture.Position', null, global);
goog.exportSymbol('proto.InkCapture.ProcessedScan', null, global);
goog.exportSymbol('proto.InkCapture.Scan', null, global);
goog.exportSymbol('proto.InkCapture.SearchDocument', null, global);
goog.exportSymbol('proto.InkCapture.Word', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InkCapture.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Point.displayName = 'proto.InkCapture.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InkCapture.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Position.displayName = 'proto.InkCapture.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Word = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.InkCapture.Word.repeatedFields_, null);
};
goog.inherits(proto.InkCapture.Word, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Word.displayName = 'proto.InkCapture.Word';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.InkCapture.Line.repeatedFields_, null);
};
goog.inherits(proto.InkCapture.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Line.displayName = 'proto.InkCapture.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Block = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.InkCapture.Block.repeatedFields_, null);
};
goog.inherits(proto.InkCapture.Block, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Block.displayName = 'proto.InkCapture.Block';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Scan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InkCapture.Scan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Scan.displayName = 'proto.InkCapture.Scan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.ProcessedScan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.InkCapture.ProcessedScan.repeatedFields_, null);
};
goog.inherits(proto.InkCapture.ProcessedScan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.ProcessedScan.displayName = 'proto.InkCapture.ProcessedScan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.InkCapture.Document.repeatedFields_, null);
};
goog.inherits(proto.InkCapture.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Document.displayName = 'proto.InkCapture.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.SearchDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InkCapture.SearchDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.SearchDocument.displayName = 'proto.InkCapture.SearchDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.DocumentLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InkCapture.DocumentLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.DocumentLocation.displayName = 'proto.InkCapture.DocumentLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InkCapture.Folder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.InkCapture.Folder.repeatedFields_, null);
};
goog.inherits(proto.InkCapture.Folder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InkCapture.Folder.displayName = 'proto.InkCapture.Folder';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Point}
 */
proto.InkCapture.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Point;
  return proto.InkCapture.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Point}
 */
proto.InkCapture.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float x = 1;
 * @return {number}
 */
proto.InkCapture.Point.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InkCapture.Point} returns this
 */
proto.InkCapture.Point.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float y = 2;
 * @return {number}
 */
proto.InkCapture.Point.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InkCapture.Point} returns this
 */
proto.InkCapture.Point.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
    topLeft: (f = msg.getTopLeft()) && proto.InkCapture.Point.toObject(includeInstance, f),
    topRight: (f = msg.getTopRight()) && proto.InkCapture.Point.toObject(includeInstance, f),
    bottomRight: (f = msg.getBottomRight()) && proto.InkCapture.Point.toObject(includeInstance, f),
    bottomLeft: (f = msg.getBottomLeft()) && proto.InkCapture.Point.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Position}
 */
proto.InkCapture.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Position;
  return proto.InkCapture.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Position}
 */
proto.InkCapture.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.InkCapture.Point;
      reader.readMessage(value,proto.InkCapture.Point.deserializeBinaryFromReader);
      msg.setTopLeft(value);
      break;
    case 2:
      var value = new proto.InkCapture.Point;
      reader.readMessage(value,proto.InkCapture.Point.deserializeBinaryFromReader);
      msg.setTopRight(value);
      break;
    case 3:
      var value = new proto.InkCapture.Point;
      reader.readMessage(value,proto.InkCapture.Point.deserializeBinaryFromReader);
      msg.setBottomRight(value);
      break;
    case 4:
      var value = new proto.InkCapture.Point;
      reader.readMessage(value,proto.InkCapture.Point.deserializeBinaryFromReader);
      msg.setBottomLeft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopLeft();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.InkCapture.Point.serializeBinaryToWriter
    );
  }
  f = message.getTopRight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.InkCapture.Point.serializeBinaryToWriter
    );
  }
  f = message.getBottomRight();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.InkCapture.Point.serializeBinaryToWriter
    );
  }
  f = message.getBottomLeft();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.InkCapture.Point.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point top_left = 1;
 * @return {?proto.InkCapture.Point}
 */
proto.InkCapture.Position.prototype.getTopLeft = function() {
  return /** @type{?proto.InkCapture.Point} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.Point, 1));
};


/**
 * @param {?proto.InkCapture.Point|undefined} value
 * @return {!proto.InkCapture.Position} returns this
*/
proto.InkCapture.Position.prototype.setTopLeft = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Position} returns this
 */
proto.InkCapture.Position.prototype.clearTopLeft = function() {
  return this.setTopLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Position.prototype.hasTopLeft = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Point top_right = 2;
 * @return {?proto.InkCapture.Point}
 */
proto.InkCapture.Position.prototype.getTopRight = function() {
  return /** @type{?proto.InkCapture.Point} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.Point, 2));
};


/**
 * @param {?proto.InkCapture.Point|undefined} value
 * @return {!proto.InkCapture.Position} returns this
*/
proto.InkCapture.Position.prototype.setTopRight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Position} returns this
 */
proto.InkCapture.Position.prototype.clearTopRight = function() {
  return this.setTopRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Position.prototype.hasTopRight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Point bottom_right = 3;
 * @return {?proto.InkCapture.Point}
 */
proto.InkCapture.Position.prototype.getBottomRight = function() {
  return /** @type{?proto.InkCapture.Point} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.Point, 3));
};


/**
 * @param {?proto.InkCapture.Point|undefined} value
 * @return {!proto.InkCapture.Position} returns this
*/
proto.InkCapture.Position.prototype.setBottomRight = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Position} returns this
 */
proto.InkCapture.Position.prototype.clearBottomRight = function() {
  return this.setBottomRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Position.prototype.hasBottomRight = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Point bottom_left = 4;
 * @return {?proto.InkCapture.Point}
 */
proto.InkCapture.Position.prototype.getBottomLeft = function() {
  return /** @type{?proto.InkCapture.Point} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.Point, 4));
};


/**
 * @param {?proto.InkCapture.Point|undefined} value
 * @return {!proto.InkCapture.Position} returns this
*/
proto.InkCapture.Position.prototype.setBottomLeft = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Position} returns this
 */
proto.InkCapture.Position.prototype.clearBottomLeft = function() {
  return this.setBottomLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Position.prototype.hasBottomLeft = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InkCapture.Word.repeatedFields_ = [6,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Word.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Word.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Word} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Word.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: (f = msg.getPosition()) && proto.InkCapture.Position.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    confidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    confidenceCharsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    splitType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    otherPartId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    otherPartText: jspb.Message.getFieldWithDefault(msg, 9, ""),
    flagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Word}
 */
proto.InkCapture.Word.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Word;
  return proto.InkCapture.Word.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Word} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Word}
 */
proto.InkCapture.Word.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.InkCapture.Position;
      reader.readMessage(value,proto.InkCapture.Position.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConfidence(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConfidenceChars(values[i]);
      }
      break;
    case 7:
      var value = /** @type {!proto.InkCapture.WordSplitTypeType} */ (reader.readEnum());
      msg.setSplitType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherPartId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherPartText(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.InkCapture.WordFlagType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFlags(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Word.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Word.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Word} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Word.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.InkCapture.Position.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getConfidenceCharsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      6,
      f
    );
  }
  f = message.getSplitType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFlagsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.Word.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Position position = 2;
 * @return {?proto.InkCapture.Position}
 */
proto.InkCapture.Word.prototype.getPosition = function() {
  return /** @type{?proto.InkCapture.Position} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.Position, 2));
};


/**
 * @param {?proto.InkCapture.Position|undefined} value
 * @return {!proto.InkCapture.Word} returns this
*/
proto.InkCapture.Word.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Word.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.InkCapture.Word.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.InkCapture.Word.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float confidence = 5;
 * @return {number}
 */
proto.InkCapture.Word.prototype.getConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated float confidence_chars = 6;
 * @return {!Array<number>}
 */
proto.InkCapture.Word.prototype.getConfidenceCharsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setConfidenceCharsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.addConfidenceChars = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.clearConfidenceCharsList = function() {
  return this.setConfidenceCharsList([]);
};


/**
 * optional WordSplitTypeType split_type = 7;
 * @return {!proto.InkCapture.WordSplitTypeType}
 */
proto.InkCapture.Word.prototype.getSplitType = function() {
  return /** @type {!proto.InkCapture.WordSplitTypeType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.InkCapture.WordSplitTypeType} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setSplitType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string other_part_id = 8;
 * @return {string}
 */
proto.InkCapture.Word.prototype.getOtherPartId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setOtherPartId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.clearOtherPartId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Word.prototype.hasOtherPartId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string other_part_text = 9;
 * @return {string}
 */
proto.InkCapture.Word.prototype.getOtherPartText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setOtherPartText = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.clearOtherPartText = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Word.prototype.hasOtherPartText = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated WordFlagType flags = 10;
 * @return {!Array<!proto.InkCapture.WordFlagType>}
 */
proto.InkCapture.Word.prototype.getFlagsList = function() {
  return /** @type {!Array<!proto.InkCapture.WordFlagType>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.InkCapture.WordFlagType>} value
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.setFlagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.InkCapture.WordFlagType} value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.addFlags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Word} returns this
 */
proto.InkCapture.Word.prototype.clearFlagsList = function() {
  return this.setFlagsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InkCapture.Line.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wordsList: jspb.Message.toObjectList(msg.getWordsList(),
    proto.InkCapture.Word.toObject, includeInstance),
    position: (f = msg.getPosition()) && proto.InkCapture.Position.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Line}
 */
proto.InkCapture.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Line;
  return proto.InkCapture.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Line}
 */
proto.InkCapture.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.InkCapture.Word;
      reader.readMessage(value,proto.InkCapture.Word.deserializeBinaryFromReader);
      msg.addWords(value);
      break;
    case 3:
      var value = new proto.InkCapture.Position;
      reader.readMessage(value,proto.InkCapture.Position.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.InkCapture.Word.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.InkCapture.Position.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.Line.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Line} returns this
 */
proto.InkCapture.Line.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Word words = 2;
 * @return {!Array<!proto.InkCapture.Word>}
 */
proto.InkCapture.Line.prototype.getWordsList = function() {
  return /** @type{!Array<!proto.InkCapture.Word>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InkCapture.Word, 2));
};


/**
 * @param {!Array<!proto.InkCapture.Word>} value
 * @return {!proto.InkCapture.Line} returns this
*/
proto.InkCapture.Line.prototype.setWordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.InkCapture.Word=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Word}
 */
proto.InkCapture.Line.prototype.addWords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.InkCapture.Word, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Line} returns this
 */
proto.InkCapture.Line.prototype.clearWordsList = function() {
  return this.setWordsList([]);
};


/**
 * optional Position position = 3;
 * @return {?proto.InkCapture.Position}
 */
proto.InkCapture.Line.prototype.getPosition = function() {
  return /** @type{?proto.InkCapture.Position} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.Position, 3));
};


/**
 * @param {?proto.InkCapture.Position|undefined} value
 * @return {!proto.InkCapture.Line} returns this
*/
proto.InkCapture.Line.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Line} returns this
 */
proto.InkCapture.Line.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Line.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 order = 4;
 * @return {number}
 */
proto.InkCapture.Line.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.InkCapture.Line} returns this
 */
proto.InkCapture.Line.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InkCapture.Block.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Block.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Block.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Block} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Block.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.InkCapture.Line.toObject, includeInstance),
    position: (f = msg.getPosition()) && proto.InkCapture.Position.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    text: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Block}
 */
proto.InkCapture.Block.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Block;
  return proto.InkCapture.Block.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Block} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Block}
 */
proto.InkCapture.Block.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.InkCapture.Line;
      reader.readMessage(value,proto.InkCapture.Line.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    case 3:
      var value = new proto.InkCapture.Position;
      reader.readMessage(value,proto.InkCapture.Position.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = /** @type {!proto.InkCapture.BlockTypeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Block.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Block.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Block} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Block.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.InkCapture.Line.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.InkCapture.Position.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.Block.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Block} returns this
 */
proto.InkCapture.Block.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Line lines = 2;
 * @return {!Array<!proto.InkCapture.Line>}
 */
proto.InkCapture.Block.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.InkCapture.Line>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InkCapture.Line, 2));
};


/**
 * @param {!Array<!proto.InkCapture.Line>} value
 * @return {!proto.InkCapture.Block} returns this
*/
proto.InkCapture.Block.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.InkCapture.Line=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Line}
 */
proto.InkCapture.Block.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.InkCapture.Line, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Block} returns this
 */
proto.InkCapture.Block.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};


/**
 * optional Position position = 3;
 * @return {?proto.InkCapture.Position}
 */
proto.InkCapture.Block.prototype.getPosition = function() {
  return /** @type{?proto.InkCapture.Position} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.Position, 3));
};


/**
 * @param {?proto.InkCapture.Position|undefined} value
 * @return {!proto.InkCapture.Block} returns this
*/
proto.InkCapture.Block.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Block} returns this
 */
proto.InkCapture.Block.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Block.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BlockTypeType type = 4;
 * @return {!proto.InkCapture.BlockTypeType}
 */
proto.InkCapture.Block.prototype.getType = function() {
  return /** @type {!proto.InkCapture.BlockTypeType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.InkCapture.BlockTypeType} value
 * @return {!proto.InkCapture.Block} returns this
 */
proto.InkCapture.Block.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.InkCapture.Block.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Block} returns this
 */
proto.InkCapture.Block.prototype.setText = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Block} returns this
 */
proto.InkCapture.Block.prototype.clearText = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Block.prototype.hasText = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Scan.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Scan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Scan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Scan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64(),
    dataSha256: jspb.Message.getFieldWithDefault(msg, 3, ""),
    variant: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    metadata: (f = msg.getMetadata()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    size: jspb.Message.getFieldWithDefault(msg, 8, 0),
    state: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Scan}
 */
proto.InkCapture.Scan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Scan;
  return proto.InkCapture.Scan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Scan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Scan}
 */
proto.InkCapture.Scan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataSha256(value);
      break;
    case 4:
      var value = /** @type {!proto.InkCapture.ImageVariantType} */ (reader.readEnum());
      msg.setVariant(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 7:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 9:
      var value = /** @type {!proto.InkCapture.ScanStateType} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Scan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Scan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Scan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Scan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.Scan.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.InkCapture.Scan.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.InkCapture.Scan.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.InkCapture.Scan.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.setData = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.clearData = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Scan.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string data_sha256 = 3;
 * @return {string}
 */
proto.InkCapture.Scan.prototype.getDataSha256 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.setDataSha256 = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.clearDataSha256 = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Scan.prototype.hasDataSha256 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ImageVariantType variant = 4;
 * @return {!proto.InkCapture.ImageVariantType}
 */
proto.InkCapture.Scan.prototype.getVariant = function() {
  return /** @type {!proto.InkCapture.ImageVariantType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.InkCapture.ImageVariantType} value
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.setVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.InkCapture.Scan.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.InkCapture.Scan} returns this
*/
proto.InkCapture.Scan.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Scan.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string document_id = 6;
 * @return {string}
 */
proto.InkCapture.Scan.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Struct metadata = 7;
 * @return {?proto.google.protobuf.Struct}
 */
proto.InkCapture.Scan.prototype.getMetadata = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 7));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.InkCapture.Scan} returns this
*/
proto.InkCapture.Scan.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Scan.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 size = 8;
 * @return {number}
 */
proto.InkCapture.Scan.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional ScanStateType state = 9;
 * @return {!proto.InkCapture.ScanStateType}
 */
proto.InkCapture.Scan.prototype.getState = function() {
  return /** @type {!proto.InkCapture.ScanStateType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.InkCapture.ScanStateType} value
 * @return {!proto.InkCapture.Scan} returns this
 */
proto.InkCapture.Scan.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InkCapture.ProcessedScan.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.ProcessedScan.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.ProcessedScan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.ProcessedScan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.ProcessedScan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scanId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blocksList: jspb.Message.toObjectList(msg.getBlocksList(),
    proto.InkCapture.Block.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.ProcessedScan}
 */
proto.InkCapture.ProcessedScan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.ProcessedScan;
  return proto.InkCapture.ProcessedScan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.ProcessedScan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.ProcessedScan}
 */
proto.InkCapture.ProcessedScan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanId(value);
      break;
    case 4:
      var value = new proto.InkCapture.Block;
      reader.readMessage(value,proto.InkCapture.Block.deserializeBinaryFromReader);
      msg.addBlocks(value);
      break;
    case 5:
      var value = /** @type {!proto.InkCapture.ProcessedScanStateType} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.ProcessedScan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.ProcessedScan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.ProcessedScan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.ProcessedScan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScanId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBlocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.InkCapture.Block.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.ProcessedScan.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.ProcessedScan} returns this
 */
proto.InkCapture.ProcessedScan.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.InkCapture.ProcessedScan.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.InkCapture.ProcessedScan} returns this
*/
proto.InkCapture.ProcessedScan.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.ProcessedScan} returns this
 */
proto.InkCapture.ProcessedScan.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.ProcessedScan.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string scan_id = 3;
 * @return {string}
 */
proto.InkCapture.ProcessedScan.prototype.getScanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.ProcessedScan} returns this
 */
proto.InkCapture.ProcessedScan.prototype.setScanId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Block blocks = 4;
 * @return {!Array<!proto.InkCapture.Block>}
 */
proto.InkCapture.ProcessedScan.prototype.getBlocksList = function() {
  return /** @type{!Array<!proto.InkCapture.Block>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InkCapture.Block, 4));
};


/**
 * @param {!Array<!proto.InkCapture.Block>} value
 * @return {!proto.InkCapture.ProcessedScan} returns this
*/
proto.InkCapture.ProcessedScan.prototype.setBlocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.InkCapture.Block=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Block}
 */
proto.InkCapture.ProcessedScan.prototype.addBlocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.InkCapture.Block, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.ProcessedScan} returns this
 */
proto.InkCapture.ProcessedScan.prototype.clearBlocksList = function() {
  return this.setBlocksList([]);
};


/**
 * optional ProcessedScanStateType state = 5;
 * @return {!proto.InkCapture.ProcessedScanStateType}
 */
proto.InkCapture.ProcessedScan.prototype.getState = function() {
  return /** @type {!proto.InkCapture.ProcessedScanStateType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.InkCapture.ProcessedScanStateType} value
 * @return {!proto.InkCapture.ProcessedScan} returns this
 */
proto.InkCapture.ProcessedScan.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InkCapture.Document.repeatedFields_ = [8,9,12,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    urisList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    tagsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    metadata: (f = msg.getMetadata()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.InkCapture.DocumentLocation.toObject(includeInstance, f),
    scansList: jspb.Message.toObjectList(msg.getScansList(),
    proto.InkCapture.Scan.toObject, includeInstance),
    myAllowedActivitiesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    icws_proto_icws_api_gateway_auth_base_pb.Permission.toObject, includeInstance),
    scansNum: jspb.Message.getFieldWithDefault(msg, 15, 0),
    state: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Document}
 */
proto.InkCapture.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Document;
  return proto.InkCapture.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Document}
 */
proto.InkCapture.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeletedBy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addUris(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 10:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 11:
      var value = new proto.InkCapture.DocumentLocation;
      reader.readMessage(value,proto.InkCapture.DocumentLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 12:
      var value = new proto.InkCapture.Scan;
      reader.readMessage(value,proto.InkCapture.Scan.deserializeBinaryFromReader);
      msg.addScans(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addMyAllowedActivities(value);
      break;
    case 14:
      var value = new icws_proto_icws_api_gateway_auth_base_pb.Permission;
      reader.readMessage(value,icws_proto_icws_api_gateway_auth_base_pb.Permission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScansNum(value);
      break;
    case 16:
      var value = /** @type {!proto.InkCapture.DocumentStateType} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUrisList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.InkCapture.DocumentLocation.serializeBinaryToWriter
    );
  }
  f = message.getScansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.InkCapture.Scan.serializeBinaryToWriter
    );
  }
  f = message.getMyAllowedActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      icws_proto_icws_api_gateway_auth_base_pb.Permission.serializeBinaryToWriter
    );
  }
  f = message.getScansNum();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.Document.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.InkCapture.Document.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_by = 3;
 * @return {string}
 */
proto.InkCapture.Document.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setCreatedBy = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearCreatedBy = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Document.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.InkCapture.Document.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.InkCapture.Document} returns this
*/
proto.InkCapture.Document.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Document.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.InkCapture.Document.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.InkCapture.Document} returns this
*/
proto.InkCapture.Document.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Document.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string deleted_by = 6;
 * @return {string}
 */
proto.InkCapture.Document.prototype.getDeletedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setDeletedBy = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearDeletedBy = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Document.prototype.hasDeletedBy = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string parent_id = 7;
 * @return {string}
 */
proto.InkCapture.Document.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Document.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated string uris = 8;
 * @return {!Array<string>}
 */
proto.InkCapture.Document.prototype.getUrisList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setUrisList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.addUris = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearUrisList = function() {
  return this.setUrisList([]);
};


/**
 * repeated string tags = 9;
 * @return {!Array<string>}
 */
proto.InkCapture.Document.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional google.protobuf.Struct metadata = 10;
 * @return {?proto.google.protobuf.Struct}
 */
proto.InkCapture.Document.prototype.getMetadata = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 10));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.InkCapture.Document} returns this
*/
proto.InkCapture.Document.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Document.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DocumentLocation location = 11;
 * @return {?proto.InkCapture.DocumentLocation}
 */
proto.InkCapture.Document.prototype.getLocation = function() {
  return /** @type{?proto.InkCapture.DocumentLocation} */ (
    jspb.Message.getWrapperField(this, proto.InkCapture.DocumentLocation, 11));
};


/**
 * @param {?proto.InkCapture.DocumentLocation|undefined} value
 * @return {!proto.InkCapture.Document} returns this
*/
proto.InkCapture.Document.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Document.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Scan scans = 12;
 * @return {!Array<!proto.InkCapture.Scan>}
 */
proto.InkCapture.Document.prototype.getScansList = function() {
  return /** @type{!Array<!proto.InkCapture.Scan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InkCapture.Scan, 12));
};


/**
 * @param {!Array<!proto.InkCapture.Scan>} value
 * @return {!proto.InkCapture.Document} returns this
*/
proto.InkCapture.Document.prototype.setScansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.InkCapture.Scan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Scan}
 */
proto.InkCapture.Document.prototype.addScans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.InkCapture.Scan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearScansList = function() {
  return this.setScansList([]);
};


/**
 * repeated string my_allowed_activities = 13;
 * @return {!Array<string>}
 */
proto.InkCapture.Document.prototype.getMyAllowedActivitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setMyAllowedActivitiesList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.addMyAllowedActivities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearMyAllowedActivitiesList = function() {
  return this.setMyAllowedActivitiesList([]);
};


/**
 * repeated Permission permissions = 14;
 * @return {!Array<!proto.InkCapture.Permission>}
 */
proto.InkCapture.Document.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.InkCapture.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, icws_proto_icws_api_gateway_auth_base_pb.Permission, 14));
};


/**
 * @param {!Array<!proto.InkCapture.Permission>} value
 * @return {!proto.InkCapture.Document} returns this
*/
proto.InkCapture.Document.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.InkCapture.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Permission}
 */
proto.InkCapture.Document.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.InkCapture.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional int32 scans_num = 15;
 * @return {number}
 */
proto.InkCapture.Document.prototype.getScansNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setScansNum = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional DocumentStateType state = 16;
 * @return {!proto.InkCapture.DocumentStateType}
 */
proto.InkCapture.Document.prototype.getState = function() {
  return /** @type {!proto.InkCapture.DocumentStateType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.InkCapture.DocumentStateType} value
 * @return {!proto.InkCapture.Document} returns this
 */
proto.InkCapture.Document.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.SearchDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.SearchDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.SearchDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.SearchDocument.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.SearchDocument}
 */
proto.InkCapture.SearchDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.SearchDocument;
  return proto.InkCapture.SearchDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.SearchDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.SearchDocument}
 */
proto.InkCapture.SearchDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.SearchDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.SearchDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.SearchDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.SearchDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.DocumentLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.DocumentLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.DocumentLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.DocumentLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institution: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    building: jspb.Message.getFieldWithDefault(msg, 6, ""),
    floor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    office: jspb.Message.getFieldWithDefault(msg, 8, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.DocumentLocation}
 */
proto.InkCapture.DocumentLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.DocumentLocation;
  return proto.InkCapture.DocumentLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.DocumentLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.DocumentLocation}
 */
proto.InkCapture.DocumentLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitution(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuilding(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.DocumentLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.DocumentLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.DocumentLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.DocumentLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution = 2;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getInstitution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setInstitution = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearInstitution = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasInstitution = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setCountry = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearCountry = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setCity = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearCity = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasCity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setAddress = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearAddress = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string building = 6;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getBuilding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setBuilding = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearBuilding = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasBuilding = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string floor = 7;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getFloor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setFloor = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearFloor = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasFloor = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string office = 8;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setOffice = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearOffice = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasOffice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string detail = 9;
 * @return {string}
 */
proto.InkCapture.DocumentLocation.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.setDetail = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.DocumentLocation} returns this
 */
proto.InkCapture.DocumentLocation.prototype.clearDetail = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.DocumentLocation.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InkCapture.Folder.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InkCapture.Folder.prototype.toObject = function(opt_includeInstance) {
  return proto.InkCapture.Folder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InkCapture.Folder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Folder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedBy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    metadata: (f = msg.getMetadata()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    myAllowedActivitiesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    icws_proto_icws_api_gateway_auth_base_pb.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InkCapture.Folder}
 */
proto.InkCapture.Folder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InkCapture.Folder;
  return proto.InkCapture.Folder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InkCapture.Folder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InkCapture.Folder}
 */
proto.InkCapture.Folder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeletedBy(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 7:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addMyAllowedActivities(value);
      break;
    case 9:
      var value = new icws_proto_icws_api_gateway_auth_base_pb.Permission;
      reader.readMessage(value,icws_proto_icws_api_gateway_auth_base_pb.Permission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InkCapture.Folder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InkCapture.Folder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InkCapture.Folder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InkCapture.Folder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getMyAllowedActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      icws_proto_icws_api_gateway_auth_base_pb.Permission.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InkCapture.Folder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.InkCapture.Folder.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.InkCapture.Folder.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.InkCapture.Folder} returns this
*/
proto.InkCapture.Folder.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Folder.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.InkCapture.Folder.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.InkCapture.Folder} returns this
*/
proto.InkCapture.Folder.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Folder.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string deleted_by = 5;
 * @return {string}
 */
proto.InkCapture.Folder.prototype.getDeletedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.setDeletedBy = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.clearDeletedBy = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Folder.prototype.hasDeletedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string parent_id = 6;
 * @return {string}
 */
proto.InkCapture.Folder.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Folder.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Struct metadata = 7;
 * @return {?proto.google.protobuf.Struct}
 */
proto.InkCapture.Folder.prototype.getMetadata = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 7));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.InkCapture.Folder} returns this
*/
proto.InkCapture.Folder.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InkCapture.Folder.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated string my_allowed_activities = 8;
 * @return {!Array<string>}
 */
proto.InkCapture.Folder.prototype.getMyAllowedActivitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.setMyAllowedActivitiesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.addMyAllowedActivities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.clearMyAllowedActivitiesList = function() {
  return this.setMyAllowedActivitiesList([]);
};


/**
 * repeated Permission permissions = 9;
 * @return {!Array<!proto.InkCapture.Permission>}
 */
proto.InkCapture.Folder.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.InkCapture.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, icws_proto_icws_api_gateway_auth_base_pb.Permission, 9));
};


/**
 * @param {!Array<!proto.InkCapture.Permission>} value
 * @return {!proto.InkCapture.Folder} returns this
*/
proto.InkCapture.Folder.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.InkCapture.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InkCapture.Permission}
 */
proto.InkCapture.Folder.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.InkCapture.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InkCapture.Folder} returns this
 */
proto.InkCapture.Folder.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


goog.object.extend(exports, proto.InkCapture);
