export enum AppMode {
  NORMAL = 0,
  DEMO = 1,
  ANONYMOUS_USER = 2,
}

export enum ContentSource {
  ICWS = 0,
  DEMO = 1,
  APP_TOUR = 2,
}

export class GlobalConstants {
  // GESTURES
  /** Minimal gesture length to be detected as "swipe" gesture. */
  public static GESTURE_SWIPE_MIN_DISTANCE = 80;
  /** Minimal gesture velocity to be detected as "swipe" gesture. */
  public static GESTURE_SWIPE_MIN_VELOCITY = 0.2;
  /** Time after which the gesture is detected as a "long press". */
  public static GESTURE_LONG_PRESS_DELAY = 1000;

  // IMAGE CACHE
  /** Local directory used to caching page images. */
  public static IMAGE_CACHE = "IMAGE_CACHE";
  /** Cached images older than this interval [ms] should be deleted. */
  public static CACHE_CLEAR_INTERVAL = 86400000 * 30; // In miliseconds [1000 (1s) * 3600 (1 hour) * 24 (1 day) = 86400000 (1 day)]

  // CONTENT UPLOAD
  /** Maximum number of pages in one single document. */
  public static MAX_PAGES_IN_DOCUMENT = 100;
  /** Maximum size of sigle document in bytes */
  public static MAX_FILE_BYTES = 20000000;
  /** Files over this size will be resized */
  public static FILE_SIZE_TO_RESIZE = 10000000;

  // OCR TASK REFRESH
  public static OCR_TASK_STATUS_REFRESH = 30000; // Time period [ms] with which tasks information is updated
  // BBOX_ACCURACY means the decimal places to which the coordinates of the bounding boxes will be compared
  public static BBOX_ACCURACY = 100000;
  // General processing params ID
  public static OCR_PROCESSING_PARAMS_GENERAL_ID = "general";
}

export enum MimeType {
  PNG = "image/png",
  JPEG = "image/jpeg",
  TIF = "image/tif",
  TIFF = "image/tiff",
  PDF = "application/pdf",
}
