// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  width: 100%;
  height: 100%;
  padding: 6px 6px 6px 0px;
}

.about-text {
  --padding-top: 32px;
  --padding-bottom: 32px;
  --padding-start: 32px;
  --padding-end: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.original-size {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  padding-top: 6px;
}

ion-label {
  font-size: 14px;
}
ion-label a {
  color: var(--ion-color-dark);
  text-decoration: underline;
  cursor: pointer;
}

ion-button {
  height: 35px;
  --border-radius: 10px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
  --background-hover: var(--ion-color-dark) !important;
  --background-hover-opacity: 0.8 !important;
}
ion-button ion-label {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: lighter;
  text-transform: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/main/header/user-menu/terms-and-conditions/terms-and-conditions.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,wBAAA;AACF;;AAEA;EACE,mBAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;AAAE;EACE,4BAAA;EACA,0BAAA;EACA,eAAA;AAEJ;;AAEA;EACE,YAAA;EACA,qBAAA;EACA,wDAAA;EACA,oDAAA;EACA,0CAAA;AACF;AAAE;EACE,qBAAA;EACA,sBAAA;EACA,eAAA;EACA,oBAAA;EACA,oBAAA;AAEJ","sourcesContent":[".main-container {\n  width: 100%;\n  height: 100%;\n  padding: 6px 6px 6px 0px;\n}\n\n.about-text {\n  --padding-top: 32px;\n  --padding-bottom: 32px;\n  --padding-start: 32px;\n  --padding-end: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.original-size {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n  font-size: 14px;\n  padding-top: 6px;\n}\n\nion-label {\n  font-size: 14px;\n  a {\n    color: var(--ion-color-dark);\n    text-decoration: underline;\n    cursor: pointer;\n  }\n}\n\nion-button {\n  height: 35px;\n  --border-radius: 10px;\n  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);\n  --background-hover: var(--ion-color-dark) !important;\n  --background-hover-opacity: 0.8 !important;\n  ion-label {\n    display: inline-block;\n    vertical-align: middle;\n    font-size: 14px;\n    font-weight: lighter;\n    text-transform: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
