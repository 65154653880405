import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";

/**
 * Guard to prevent loading module if the user is not authenticated.
 */
@Injectable({
  providedIn: "root",
})
export class AuthInclAnonymGuard {
  /** @ignore */
  constructor(private authService: AuthenticationService, private router: Router) {}

  /**
   * Returns <code>true</code>, if the user is authenticated already.
   * If not, the application is redirected to login page.
   * @returns
   */
  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        // Modules can be load if the application is in NORMAL MODE or ANONYMOUS_USER mode
        if (isAuthenticated) {
          return true;
        } else {
          // If a redirect to the login page should be provided, it is good to pass query parameters from the origin URL
          // so that information message (related to "action" parameter value) could be displayed on the login page.
          // Standard parameter passing via Route is not possible due to the use of the useHash=true Strategy.
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("action")) {
            this.router.navigate(["/login"], {
              queryParams: { action: urlParams.get("action") },
            });
          } else {
            this.router.navigate(["/login"]);
          }
          return false;
        }
      })
    );
  }
}
