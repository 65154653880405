// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

#main-button-text {
  height: 30px;
  border-radius: 4px 0px 0px 4px;
  padding-left: 12px;
  padding-right: 6px;
  background-color: var(--ion-color-light);
  white-space: nowrap;
}
#main-button-text ion-label {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}

#main-button-chevron {
  height: 30px;
  border-radius: 0px 4px 4px 0px;
  margin: 0px 6px 0px 0px;
  padding: 0px 6px 0px 6px;
  background-color: var(--ion-color-light);
}

#main-button-text:hover {
  background-color: var(--ion-color-light-shade);
}

#main-button-chevron:hover {
  background-color: var(--ion-color-light-shade);
}

#options {
  position: absolute;
  right: 3px;
  z-index: 100;
  border-radius: 4px;
  margin-top: 3px;
  background-color: var(--ion-color-light);
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.5) !important;
}

#option-row {
  --background: var(--ion-color-light);
  --background-hover: var(--ion-color-light-shade);
  --background-hover-opacity: 1;
  --min-height: 30px;
}
#option-row ion-label {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-components/button-dropdown/button-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,wCAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AAEJ;;AAEA;EACE,YAAA;EACA,8BAAA;EACA,uBAAA;EACA,wBAAA;EACA,wCAAA;AACF;;AAEA;EACE,8CAAA;AACF;;AAEA;EACE,8CAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,wCAAA;EACA,qDAAA;AACF;;AAEA;EACE,oCAAA;EACA,gDAAA;EACA,6BAAA;EACA,kBAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AAEJ","sourcesContent":["#main-button {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n#main-button-text {\n  height: 30px;\n  border-radius: 4px 0px 0px 4px;\n  padding-left: 12px;\n  padding-right: 6px;\n  background-color: var(--ion-color-light);\n  white-space: nowrap;\n  ion-label {\n    padding: 0px;\n    margin: 0px;\n    font-size: 14px;\n  }\n}\n\n#main-button-chevron {\n  height: 30px;\n  border-radius: 0px 4px 4px 0px;\n  margin: 0px 6px 0px 0px;\n  padding: 0px 6px 0px 6px;\n  background-color: var(--ion-color-light);\n}\n\n#main-button-text:hover {\n  background-color: var(--ion-color-light-shade);\n}\n\n#main-button-chevron:hover {\n  background-color: var(--ion-color-light-shade);\n}\n\n#options {\n  position: absolute;\n  right: 3px;\n  z-index: 100;\n  border-radius: 4px;\n  margin-top: 3px;\n  background-color: var(--ion-color-light);\n  box-shadow: 6px 6px 6px rgb(0, 0, 0, 0.5) !important;\n}\n\n#option-row {\n  --background: var(--ion-color-light);\n  --background-hover: var(--ion-color-light-shade);\n  --background-hover-opacity: 1;\n  --min-height: 30px;\n  ion-label {\n    padding: 0px;\n    margin: 0px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
