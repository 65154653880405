import { Injectable } from "@angular/core";
import { Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { LoggingService } from "../services/log.service";

/** @ignore */
const TAG = "AutoLoginGuard";

/**
 * Performs redirect to home page if the user is authenticated already.
 * Is used in login page route to skip this page in case the user is authenticated.
 */
@Injectable({
  providedIn: "root",
})
export class AutoLoginGuard  {
  /** @ignore */
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private logService: LoggingService
  ) {}

  /**
   * If the user is authenticated, application is redirected to home page (/).
   * In other case, <code>true</code> is returned, so the login page is normally loaded.
   * @returns
   */
  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        if (isAuthenticated) {
          this.logService.info(
            TAG,
            "Auto login successfull -> redirect to home page"
          );
          // Directly open inside area
          this.router.navigateByUrl("/", { replaceUrl: true });
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }
}
