/**
 * @fileoverview gRPC-Web generated client stub for InkCapture
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.6
// source: icws_proto/icws_api_gateway/iam.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as icws_proto_icws_api_gateway_iam_get_licence_info_pb from '../../icws_proto/icws_api_gateway/iam/get_licence_info_pb';
import * as icws_proto_icws_api_gateway_iam_get_user_pb from '../../icws_proto/icws_api_gateway/iam/get_user_pb';
import * as icws_proto_icws_api_gateway_iam_update_user_pb from '../../icws_proto/icws_api_gateway/iam/update_user_pb';


export class IAMClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetUser = new grpcWeb.MethodDescriptor(
    '/InkCapture.IAM/GetUser',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_iam_get_user_pb.GetUserRequest,
    icws_proto_icws_api_gateway_iam_get_user_pb.GetUserResponse,
    (request: icws_proto_icws_api_gateway_iam_get_user_pb.GetUserRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_iam_get_user_pb.GetUserResponse.deserializeBinary
  );

  getUser(
    request: icws_proto_icws_api_gateway_iam_get_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_iam_get_user_pb.GetUserResponse>;

  getUser(
    request: icws_proto_icws_api_gateway_iam_get_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_iam_get_user_pb.GetUserResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_iam_get_user_pb.GetUserResponse>;

  getUser(
    request: icws_proto_icws_api_gateway_iam_get_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_iam_get_user_pb.GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.IAM/GetUser',
        request,
        metadata || {},
        this.methodDescriptorGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.IAM/GetUser',
    request,
    metadata || {},
    this.methodDescriptorGetUser);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/InkCapture.IAM/UpdateUser',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserRequest,
    icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserResponse,
    (request: icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserResponse.deserializeBinary
  );

  updateUser(
    request: icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserResponse>;

  updateUser(
    request: icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserResponse>;

  updateUser(
    request: icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_iam_update_user_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.IAM/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.IAM/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorGetLicenceInfo = new grpcWeb.MethodDescriptor(
    '/InkCapture.IAM/GetLicenceInfo',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoRequest,
    icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoResponse,
    (request: icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoResponse.deserializeBinary
  );

  getLicenceInfo(
    request: icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoResponse>;

  getLicenceInfo(
    request: icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoResponse>;

  getLicenceInfo(
    request: icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_iam_get_licence_info_pb.GetLicenceInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.IAM/GetLicenceInfo',
        request,
        metadata || {},
        this.methodDescriptorGetLicenceInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.IAM/GetLicenceInfo',
    request,
    metadata || {},
    this.methodDescriptorGetLicenceInfo);
  }

}

