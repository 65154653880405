import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import {
  DocumentOcrState,
  IccContent,
  PageOcrState,
  WordChangeType,
  WordSplitType,
} from "../models/icc-content.model";
import { v4 as uuidv4 } from "uuid";
import { MimeType } from "../models/common";
import { IccErrorClass, LoggingService } from "./log.service";
import {
  DocumentStateType,
  ScanStateType,
} from "../proto/generated/icws_proto/icws_api_gateway/types_pb";
import {
  WordFlagType,
  WordSplitTypeType,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/storage_types_pb";

const TAG = "SharedService";
const RETRY_MAX_ATTEMPTS = 5; // Maximum number of retryPromise attempts.

/** JS library for converting tiff files to pdf: https://github.com/seikichi/tiff.js */
declare var Tiff: any;

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private logService: LoggingService) {}

  //
  // RETRY MECHANISM
  //
  async retryPromise<T>(
    fn: () => Promise<T>,
    functionName: string,
    params: any = null,
    maxAttempts: number = RETRY_MAX_ATTEMPTS,
    attempt: number = 1,
    err?: any
  ): Promise<T> {
    const delay = 2 * (attempt - 1) * 200;
    if (err && (err.code === IccErrorClass.ERR_AUTH || err.code === IccErrorClass.ERR_QUOTA)) {
      // Fatal error -> do not try again
      attempt = maxAttempts + 1;
      Promise.reject(
        "Critical error while calling " + functionName + " (code " + err.code + "): " + err.message
      );
    } else if (err && attempt <= maxAttempts) {
      this.logService.warn(
        TAG,
        attempt - 1 + ". call of " + functionName + " failed (next try in " + delay + "ms).",
        params,
        err
      );
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    return attempt > maxAttempts
      ? Promise.reject(err)
      : fn().catch((error) =>
          this.retryPromise(fn, functionName, params, maxAttempts, ++attempt, error)
        );
  }

  static generateGuid(): string {
    return uuidv4();
    // return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    //   /[xy]/g,
    //   function (c) {
    //     var r = (Math.random() * 16) | 0,
    //       v = c == "x" ? r : (r & 0x3) | 0x8;
    //     return v.toString(16);
    //   }
    // );
  }

  static getPathAsString(
    contentTree: IccContent[],
    currentItemId: string,
    rootNodeId: string,
    rootNodeName: string = "/"
  ): string {
    const recursiveCall = (itemId: string): string => {
      const node: IccContent = SharedService.findNodeInTree(contentTree, itemId);
      if (node) {
        return (
          recursiveCall(node.parentId) +
          " / " +
          (node.icwsId !== rootNodeId ? node.name : rootNodeName)
        );
      } else {
        return "";
      }
    };
    return recursiveCall(currentItemId).substring(3); // remove first " / "
  }

  /**
   * Returns path to <code>currentItem</code> as array of pairs node_name:node_id.
   * It allows to render clickable path to node in template.
   * @param {IccContent[]} contentTree Complete storage content in tree form.
   * @param {string} currentItemId The node to which the path is to be created
   * @returns {Array<nodeName: string; nodeId: string>} Array with nodes on the path to <code>currentItem<code>.
   */
  static getPathAsArray(
    contentTree: IccContent[],
    currentItemId: string,
    rootNodeId: string,
    rootNodeName: string = "/"
  ): Array<{ nodeName: string; nodeId: string }> {
    let path: Array<{ nodeName: string; nodeId: string }> = [];
    const recursivePathCall = (currentItemId: string) => {
      if (currentItemId != null) {
        const node: IccContent = SharedService.findNodeInTree(contentTree, currentItemId);
        path.splice(0, 0, {
          nodeName: node.icwsId !== rootNodeId ? node.name : rootNodeName,
          nodeId: node.icwsId,
        });
        recursivePathCall(node.parentId);
      }
    };
    if (contentTree.length > 0) {
      recursivePathCall(currentItemId);
    }
    return path;
  }

  /**
   * Deep copy of object
   * @param T Generic type of target/copied value.
   * @param source Source value to be copied.
   */
  public static deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map((item) => this.deepCopy(item))
      : source instanceof Date
      ? new Date(source.getTime())
      : source && typeof source === "object"
      ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
          Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
          o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
          return o;
        }, Object.create(Object.getPrototypeOf(source)))
      : (source as T);
  }

  static findNodeInTree(contentTree: IccContent[], nodeId: string): IccContent {
    const findInChildren = (nodes: IccContent[], idToSeaerch: string): IccContent => {
      let result;
      nodes.some(
        (child) =>
          (child.icwsId === idToSeaerch && (result = child)) ||
          (result = findInChildren(child.children || [], idToSeaerch))
      );
      return result;
    };
    if (nodeId && contentTree.length > 0) {
      return findInChildren(contentTree, nodeId);
    } else {
      return null;
    }
  }

  static removeEscapedChars(data: string): string {
    return data.replace(/&quot;/g, '"');
    // .replace(/\\n/g, "\\n")
    // .replace(/\\'/g, "\\'")
    // .replace(/\\"/g, '\\"')
    // .replace(/\\&/g, "\\&")
    // .replace(/\\r/g, "\\r")
    // .replace(/\\t/g, "\\t")
    // .replace(/\\b/g, "\\b")
    // .replace(/\\f/g, "\\f");
  }

  static getImageMimeType(imageData: Uint8Array | string) {
    var dataArray: Uint8Array;
    if (imageData instanceof Uint8Array) {
      dataArray = imageData;
    } else {
      dataArray = new Uint8Array(new ArrayBuffer(imageData.length));
      for (let x = 0; x < imageData.length; x++) {
        dataArray[x] = imageData.charCodeAt(x);
      }
    }
    var header = "";
    for (var i = 0; i < 4; i++) {
      header += dataArray[i].toString(16);
    }
    if (header === "89504e47") return "image/png";
    if (header === "47494638") return "image/gif";
    if (header.substr(0, 6) === "ffd8ff") return "image/jpeg";
    if (header.substr(0, 4) === "424d") return "image/bmp";
    if (header.substr(0, 4) === "4949") return "image/tiff";
    if (header.substr(0, 4) === "4d4d") return "image/tiff";
    return null;
  }

  static getExtensionFromMimeType(mimeType: string): string {
    switch (mimeType.toLocaleLowerCase()) {
      case "image/png":
        return "png";
      case "image/jpeg":
        return "jpg";
      case "image/bmp":
        return "bmp";
      case "image/tiff":
        return "tif";
      default:
        return mimeType.toLocaleLowerCase().slice(mimeType.indexOf("/"));
    }
  }

  static calculateSHA256Hash(data: ArrayBuffer): string {
    function arrayBufferToWordArray(data: ArrayBuffer) {
      var i8a = new Uint8Array(data);
      var a = [];
      for (var i = 0; i < i8a.length; i += 4) {
        a.push((i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]);
      }
      return CryptoJS.lib.WordArray.create(a, i8a.length);
    }

    const hash = CryptoJS.SHA256(arrayBufferToWordArray(data));
    return hash.toString(CryptoJS.enc.Hex);
  }

  // If you have very large array buffers then the apply may fail and you may need to chunk the buffer (based on the one posted by @RohitSengar).
  // Note that this is only correct if your buffer only contains non-multibyte ASCII characters.
  static convertUint8ToBase64(u8a: Uint8Array) {
    var CHUNK_SZ = 0x8000;
    var c = [];
    for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
      c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
    }
    return btoa(c.join(""));
  }

  static convertArrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    let u8a: Uint8Array = new Uint8Array(arrayBuffer);
    return this.convertUint8ToBase64(u8a);
  }

  static convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  static convertBase64ToBlob(base64Data: string) {
    // Remove first part "data:${mimeType};base64,"
    const byteString = window.atob(base64Data.slice(base64Data.indexOf(",") + 1));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const mimeType = base64Data.slice("data:".length, base64Data.indexOf(";"));
    const blob = new Blob([int8Array], {
      type: mimeType,
    });
    return blob;
  }

  /**
   * Format bytes as human-readable text.
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   * @return Formatted string.
   */
  static humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + " " + units[u];
  }

  /**
   * Export an image in desired format with resize option
   * @param {ArrayBuffer} source Image data as ArrayBuffer.
   * @param {string} mimeType Mimetype of source image.
   * @param {{ width?: number; height?: number; resizeRatio?: number }} resizeRequest Options for image resize. If resizeRatio is set, width and height are ignored. If width or height is set to 0, it is computed from the second dimension to keep aspect ratio.
   * @param {string} outputType Required mimetype of generated output image.
   * @param { number} outputQuality Required quality of output image (0-1, 1 is the highest).
   * @returns Image data generated from source image in base64.
   */
  static processImage(
    source: ArrayBuffer,
    mimeType: string,
    resizeRequest: { width?: number; height?: number; resizeRatio?: number },
    outputType: string,
    outputQuality: number
  ): Promise<string> {
    let canvas: HTMLCanvasElement;
    return new Promise((response, reject) => {
      if (mimeType === MimeType.TIF || mimeType === MimeType.TIFF) {
        let tiff = new Tiff({ buffer: source });
        canvas = tiff.toCanvas();
        const resizedBase64 = canvas.toDataURL(outputType, outputQuality);
        const resizedBase64Content = resizedBase64.substring(resizedBase64.indexOf(";base64,") + 8);
        response(resizedBase64Content);
      } else {
        const base64Image = this.base64FromArrayBuffer(source);
        const base64Src = `data:${mimeType};base64,${base64Image}`;
        const img = new Image();
        img.src = base64Src;
        img.onload = () => {
          const finalSize = this.computeResize(
            { width: img.width, height: img.height },
            resizeRequest
          );
          canvas = document.createElement("canvas");
          canvas.width = finalSize.width;
          canvas.height = finalSize.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, finalSize.width, finalSize.height);
          const resizedBase64 = canvas.toDataURL(outputType, outputQuality);
          const resizedBase64Content = resizedBase64.substring(
            resizedBase64.indexOf(";base64,") + 8
          );
          response(resizedBase64Content);
        };
        img.onerror = (error) => {
          reject(error);
        };
      }
    });
  }

  private static computeResize(
    originalSize: { width: number; height: number },
    resizeRequest: { width?: number; height?: number; resizeRatio?: number }
  ): { width: number; height: number } {
    let finalWidth: number;
    let finalHeight: number;

    if (resizeRequest.resizeRatio > 0) {
      finalWidth = Math.round(originalSize.width * resizeRequest.resizeRatio);
      finalHeight = Math.round(originalSize.height * resizeRequest.resizeRatio);
    } else {
      if (resizeRequest.width > 0 && resizeRequest.height > 0) {
        finalWidth = resizeRequest.width;
        finalHeight = resizeRequest.height;
      } else if (resizeRequest.width == 0 && resizeRequest.height > 0) {
        finalWidth = Math.round(originalSize.width * (resizeRequest.height / originalSize.height));
        finalHeight = resizeRequest.height;
      } else if (resizeRequest.width > 0 && resizeRequest.height == 0) {
        finalWidth = resizeRequest.width;
        finalHeight = Math.round(originalSize.height * (resizeRequest.width / originalSize.width));
      } else {
        finalWidth = originalSize.width;
        finalHeight = originalSize.height;
      }
    }
    return { width: finalWidth, height: finalHeight };
  }

  static getImageSize(
    source: ArrayBuffer,
    mimeType: string
  ): Promise<{ width: number; height: number }> {
    return new Promise((response, reject) => {
      if (mimeType === MimeType.JPEG || mimeType === MimeType.PNG) {
        const base64Image = SharedService.base64FromArrayBuffer(source);
        const base64Src = `data:${mimeType};base64,${base64Image}`;
        const img = new Image();
        img.src = base64Src;
        img.onload = () => {
          response({ width: img.width, height: img.height });
        };
        img.onerror = (error) => {
          reject(error);
        };
      } else if (mimeType === MimeType.TIF || mimeType === MimeType.TIFF) {
        let tiff = new Tiff({ buffer: source });
        let canvas: HTMLCanvasElement = tiff.toCanvas();
        response({ width: canvas.width, height: canvas.height });
      } else {
        response({ width: 0, height: 0 });
      }
    });
  }

  /**
    Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string then
    use window.btoa' step. According to my tests, this appears to be a faster approach:
    http://jsperf.com/encoding-xhr-image-data/5
    MIT LICENSE
    Copyright 2011 Jon Leighton
    Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
    The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE. 
  */
  static base64FromArrayBuffer(arrayBuffer: ArrayBuffer) {
    var base64 = "";
    var encodings = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;
    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63; // 63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
      chunk = bytes[mainLength];

      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3   = 2^2 - 1

      base64 += encodings[a] + encodings[b] + "==";
    } else if (byteRemainder == 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; // 15    = 2^4 - 1

      base64 += encodings[a] + encodings[b] + encodings[c] + "=";
    }

    return base64;
  }

  static uint8ArrayToArrayBuffer(array: Uint8Array): ArrayBuffer {
    return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset);
  }

  /**
   * CONTENT METHODS
   */

  static getIccPageState(icwsScanStateType: ScanStateType): PageOcrState {
    switch (icwsScanStateType) {
      case ScanStateType.SCAN_STATE_TYPE_UNKNOWN:
        return PageOcrState.UNKNOWN;
      case ScanStateType.SCAN_STATE_TYPE_NEW:
        return PageOcrState.NOT_STARTED;
      case ScanStateType.SCAN_STATE_TYPE_IN_PROGRESS:
        return PageOcrState.IN_PROGRESS;
      case ScanStateType.SCAN_STATE_TYPE_INDEXING:
        return PageOcrState.INDEXING;
      case ScanStateType.SCAN_STATE_TYPE_FINISHED_OK:
        return PageOcrState.FINISHED_OK;
      case ScanStateType.SCAN_STATE_TYPE_FINISHED_ERR:
        return PageOcrState.FINISHED_ERR;
      default:
        return PageOcrState.UNKNOWN;
    }
  }

  static getIccDocumentState(icwsDocumentStateType: DocumentStateType): DocumentOcrState {
    switch (icwsDocumentStateType) {
      case DocumentStateType.DOCUMENT_STATE_TYPE_UNKNOWN:
        return DocumentOcrState.UNKNOWN;
      case DocumentStateType.DOCUMENT_STATE_TYPE_NEW:
        return DocumentOcrState.NOT_STARTED;
      case DocumentStateType.DOCUMENT_STATE_TYPE_IN_PROGRESS:
        return DocumentOcrState.IN_PROGRESS;
      case DocumentStateType.DOCUMENT_STATE_TYPE_FINISHED_OK:
        return DocumentOcrState.FINISHED_OK;
      case DocumentStateType.DOCUMENT_STATE_TYPE_FINISHED_ERR:
        return DocumentOcrState.FINISHED_ERR;
      //TODO: Missig state DocumentOcrState.NOT_COMPLETE
      default:
        return DocumentOcrState.UNKNOWN;
    }
  }

  static getIccWordSplitType(icwsSplitType: WordSplitTypeType): WordSplitType {
    switch (icwsSplitType) {
      case WordSplitTypeType.NOT_SPLIT:
        return WordSplitType.NOT_SPLITTED;
      case WordSplitTypeType.FIRST_PART_SPLIT:
        return WordSplitType.FIRST_PART;
      case WordSplitTypeType.SECOND_PART_SPLIT:
        return WordSplitType.SECOND_PART;
      default:
        return WordSplitType.UNKNOWN;
    }
  }

  static getIccWordChangeFlags(icwsFlagType: WordFlagType[]): WordChangeType[] {
    let iccFlags: WordChangeType[] = [];
    icwsFlagType.forEach((flag) => {
      switch (flag) {
        case WordFlagType.FLAG_ADDED_BY_LM:
          iccFlags.push(WordChangeType.ADDED_BY_LM);
        case WordFlagType.FLAG_ADDED_BY_USER:
          iccFlags.push(WordChangeType.ADDED_BY_USER);
        case WordFlagType.FLAG_CORRECTED_BY_LM:
          iccFlags.push(WordChangeType.CORRECTED_BY_LM);
        case WordFlagType.FLAG_CORRECTED_BY_USER:
          iccFlags.push(WordChangeType.CORRECTED_BY_USER);
        default:
          iccFlags.push(WordChangeType.UNKNOWN);
      }
    });
    return iccFlags.length > 0 ? iccFlags : null;
  }

  static addWordChangeFlag(currFlags: WordChangeType[], newFlag: WordChangeType): WordChangeType[] {
    //
    // TODO - ošetřit aby tam přidávaná hodnota nebyla dvakrát
    if (!currFlags) currFlags = [];
    currFlags.push(newFlag);
    return currFlags;
  }

  // static downloadBase64File(contentBase64, fileName) {
  //   const linkSource = contentBase64;
  //   const downloadLink = document.createElement("a");
  //   document.body.appendChild(downloadLink);

  //   downloadLink.href = linkSource;
  //   downloadLink.target = "_self";
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // }
}
