import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-button-dropdown",
  templateUrl: "./button-dropdown.component.html",
  styleUrls: ["./button-dropdown.component.scss"],
})
export class ButtonDropdownComponent implements OnInit, OnChanges {
  /** Definition of button options */
  @Input() buttonOptions: ButtonDropdownOptions;
  /** Emmits the event when user clicks on main button */
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  /** Emmits the event when user changes the selection */
  @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

  /** Currently selected button option */
  selectedOption: any;
  /** Indicates opened list of options for button */
  optionsListActive: boolean = false;

  /** @ignore Indicates click outside button component to hide the list od button options automatically */
  private wasInside = false;

  @HostListener("click")
  clickInside() {
    this.wasInside = true;
  }

  @HostListener("document:click")
  clickout() {
    if (!this.wasInside) {
      this.optionsListActive = false;
    }
    this.wasInside = false;
  }

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["buttonOptions"]) {
      this.selectedOption = this.buttonOptions.defaultOptionKey;
      this.onSelectionChanged.emit(this.selectedOption);
    }
  }

  onSearch() {
    this.optionsListActive = false;
    this.onButtonClicked.emit(this.selectedOption);
  }

  onButtonOptionChange(selectedOptionKey: any) {
    if (this.selectedOption != selectedOptionKey) this.onSelectionChanged.emit(selectedOptionKey);
    this.selectedOption = selectedOptionKey;
    this.optionsListActive = false;
  }

  getButtonOptionLabel(requestedKey: any): string {
    const selectedOption = this.buttonOptions.options.find(
      (item) => item.optionKey === requestedKey
    );
    if (selectedOption) return selectedOption.optionLabel;
    else return null;
  }
}

export interface ButtonDropdownOptions {
  defaultOptionKey: any;
  options: { optionKey: any; optionLabel: string; optionDescription: string }[];
}
