// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.page-name-label {
  flex: 0 0 auto;
  color: var(--ion-color-primary);
  font-size: 14px;
  font-weight: 600;
  padding-right: 6px;
  padding-bottom: 6px;
  cursor: default;
  border-bottom: 1px solid var(--ion-color-medium);
  margin-bottom: 16px;
}

.nameBox {
  resize: none;
  flex: 1;
  width: 100%;
  max-height: 42px;
  border-color: var(--ion-color-medium);
  border-width: 1px;
  border-style: dashed;
  border-radius: 6px;
  margin: 0px 12px 6px 0px;
  --padding-start: 12px;
  --padding-end: 12px;
  background: var(--ion-color-light) !important;
  --highlight-color: unset;
  outline: none !important;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.buttons ion-button {
  min-height: 32px;
  height: 32px;
  padding-left: 6px;
}

@media screen and (max-width: 450px) {
  .btn-hide-text {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared-components/edit-popup/edit-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,gDAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,OAAA;EACA,WAAA;EACA,gBAAA;EACA,qCAAA;EACA,iBAAA;EACA,oBAAA;EACA,kBAAA;EACA,wBAAA;EACA,qBAAA;EACA,mBAAA;EACA,6CAAA;EACA,wBAAA;EACA,wBAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;EACA,yBAAA;AACF;AAAE;EACE,gBAAA;EACA,YAAA;EACA,iBAAA;AAEJ;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".content-wrapper {\n  display: flex;\n  flex-flow: column nowrap;\n  width: 100%;\n  height: 100%;\n  padding: 16px;\n}\n\n.page-name-label {\n  flex: 0 0 auto;\n  color: var(--ion-color-primary);\n  font-size: 14px;\n  font-weight: 600;\n  padding-right: 6px;\n  padding-bottom: 6px;\n  cursor: default;\n  border-bottom: 1px solid var(--ion-color-medium);\n  margin-bottom: 16px;\n}\n\n.nameBox {\n  resize: none;\n  flex: 1;\n  width: 100%;\n  max-height: 42px;\n  border-color: var(--ion-color-medium);\n  border-width: 1px;\n  border-style: dashed;\n  border-radius: 6px;\n  margin: 0px 12px 6px 0px;\n  --padding-start: 12px;\n  --padding-end: 12px;\n  background: var(--ion-color-light) !important;\n  --highlight-color: unset;\n  outline: none !important;\n}\n\n.buttons {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-end;\n  ion-button {\n    min-height: 32px;\n    height: 32px;\n    padding-left: 6px;\n  }\n}\n\n@media screen and (max-width: 450px) {\n  .btn-hide-text {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
