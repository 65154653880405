import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit {
  originalSize: boolean = true;
  documentToDisplay: string = "";

  constructor(private modalController: ModalController, private translate: TranslateService) {
    this.documentToDisplay = this.translate.instant("user-menu.termsMsg17Url");
  }

  ngOnInit() {}

  changeDocument(document: string) {
    this.documentToDisplay = this.translate.instant(document);
  }

  /**
   * Closes modal window.
   */
  async closeWindow() {
    await this.modalController.dismiss();
  }
}
