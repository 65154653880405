import { Injectable } from "@angular/core";
import { IccProcessingParams } from "../models/icc-content.model";
import { ProcessingParams } from "../proto/generated/icws_proto/icws_api_gateway/processing/base_pb";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ProcessingParamsService {
  constructor(private translate: TranslateService) {}

  getProcessingParams(): IccProcessingParams[] {
    return [
      {
        id: ProcessingParamsId.CZECH_LM_2,
        hash: null,
        version: 1,
        icws_lm: { use_lm: true, lm_lang: "ces", max_edit_distance: 2 },
      },
      {
        id: ProcessingParamsId.NO_LM,
        hash: null,
        version: 1,
        icws_lm: { use_lm: false },
      },
    ];
  }

  getProcessingParamsDetail(): { id: string; label: string; description: string }[] {
    return [
      {
        id: ProcessingParamsId.CZECH_LM_2,
        label: this.translate.instant("procParams.CzechLm2Label"),
        description: this.translate.instant("procParams.CzechLm2Desc"),
      },
      {
        id: ProcessingParamsId.NO_LM,
        label: this.translate.instant("procParams.NoLmLabel"),
        description: this.translate.instant("procParams.NoLmDesc"),
      },
    ];
  }

  getProcessingParamsById(processingParamsId: ProcessingParamsId): IccProcessingParams {
    const commonProcessingParams = this.getProcessingParams();
    switch (processingParamsId) {
      case ProcessingParamsId.CZECH_LM_2:
        return commonProcessingParams.find((item) => item.id === ProcessingParamsId.CZECH_LM_2);
        break;
      case ProcessingParamsId.NO_LM:
        return commonProcessingParams.find((item) => item.id === ProcessingParamsId.NO_LM);
        break;
      default:
        return {
          id: ProcessingParamsId.UNKNOWN,
          hash: null,
          version: null,
          icws_lm: null,
          icws_seg: null,
        };
    }
  }

  convertIcwsParamsToIccParams(icwsParams: ProcessingParams): IccProcessingParams {
    if (icwsParams) {
      let iccParams: IccProcessingParams = {
        id: ProcessingParamsId.CUSTOM,
        hash: icwsParams.getHashSha256(),
        version: icwsParams.getVersion(),
        icws_lm: icwsParams.getIcwsLm().toJavaScript(),
        icws_seg: icwsParams.getIcwsSeg().toJavaScript(),
      };
      const namedParamsFound = this.getProcessingParams().find((item) =>
        this.equalProcessingParams(iccParams, item)
      );
      if (namedParamsFound) {
        iccParams.id = namedParamsFound.id;
      }
      return iccParams;
    } else {
      return this.getProcessingParamsById(ProcessingParamsId.UNKNOWN);
    }
  }

  equalProcessingParams(par1: IccProcessingParams, par2: IccProcessingParams): boolean {
    let isEqual: boolean = true;
    if (par1?.icws_lm?.use_lm !== par2?.icws_lm?.use_lm) isEqual = false;
    if (par1?.icws_lm?.lm_lang !== par2?.icws_lm?.lm_lang) isEqual = false;
    if (par1?.icws_lm?.max_edit_distance !== par2?.icws_lm?.max_edit_distance) isEqual = false;
    return isEqual;
  }
}

export enum ProcessingParamsId {
  UNKNOWN = "UNKNOWN",
  CUSTOM = "CUSTOM",
  NO_LM = "NO_LM",
  CZECH_LM_2 = "CZECH_LM_2",
}
