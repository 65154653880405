import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { filter, mergeMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AppMode } from "../models/common";
import { AuthenticationService } from "../services/authentication.service";
import { GlobalService } from "../services/global.service";
import { IcwsAuthService } from "../services/icws-auth.service";
import { IcwsLoggingService } from "../services/icws-logging.service";
import { IcwsProcessingService } from "../services/icws-processing.service";
import { IcwsStorageService } from "../services/icws-storage.service";
import { IcwsSearchingService } from "../services/icws-searching.service";
import { LocalStorageService, STORAGE_KEY_TOKEN } from "../services/local-storage.service";
import { IcwsUpdatingService } from "../services/icws-updating.service";

@Injectable({
  providedIn: "root",
})
export class AuthSharedGuard {
  /** @ignore */
  constructor(
    private authService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private globalService: GlobalService,
    private icwsStorageService: IcwsStorageService,
    private icwsAuthService: IcwsAuthService,
    private icwsProcessingService: IcwsProcessingService,
    private icwsLoggingService: IcwsLoggingService,
    private icwsSearchingService: IcwsSearchingService,
    private icwsUpdatingService: IcwsUpdatingService
  ) {}

  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      mergeMap(async (isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        } else {
          // Set the whole application environment to the mode, where anonymous user can act
          await this.setAnonymousUserEnvironment();
          return true;
        }
      })
    );
  }

  async setAnonymousUserEnvironment(): Promise<void> {
    // Set App to the mode, where some elements should be disabled
    this.globalService.setAppMode(AppMode.ANONYMOUS_USER);
    // Set token in "memory" local storage to anonymous user (and do not store the token in local storage)
    await this.localStorageService.set(STORAGE_KEY_TOKEN, environment.anonymous_user_token, true);
    // Set information about the current user to values corresponding to anonymous user
    await this.authService.loadToken();
    // Init authentication header for communication with ICWS to the anonymous_user_token which has been set before in "memory" local storage
    await this.icwsStorageService.initMetadata(environment.anonymous_user_token);
    await this.icwsAuthService.initMetadata(environment.anonymous_user_token);
    await this.icwsProcessingService.initMetadata(environment.anonymous_user_token);
    await this.icwsLoggingService.initMetadata(environment.anonymous_user_token);
    await this.icwsSearchingService.initMetadata(environment.anonymous_user_token);
    await this.icwsUpdatingService.initMetadata(environment.anonymous_user_token);
  }
}
