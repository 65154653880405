// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
  min-width: 0;
  padding: 0px;
  margin: 0px;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-components/side-panel/side-panel-content/side-panel-content.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[":host {\r\n  flex-grow: 1;\r\n  min-width: 0;\r\n  padding: 0px;\r\n  margin: 0px;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
