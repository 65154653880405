// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-container {
  height: 100%;
  width: 100%;
  background: var(--ion-color-light-shade);
}

.resize-toggle {
  position: absolute;
  top: 8px;
  left: 24px;
  height: 35px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 32px;
  height: 35px;
  --border-radius: 10px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
  --background-hover: var(--ion-color-dark);
  --background-hover-opacity: 0.8;
  z-index: 100;
}
.close-btn ion-label {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
}
.close-btn fa-icon {
  font-size: 16px;
  padding-right: 12px;
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-components/pdf-view/pdf-view.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,wCAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,wDAAA;EACA,yCAAA;EACA,+BAAA;EACA,YAAA;AACF;AAAE;EACE,qBAAA;EACA,sBAAA;EACA,eAAA;EACA,mBAAA;EACA,oBAAA;AAEJ;AAAE;EACE,eAAA;EACA,mBAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".document-container {\n  height: 100%;\n  width: 100%;\n  background: var(--ion-color-light-shade);\n}\n\n.resize-toggle {\n  position: absolute;\n  top: 8px;\n  left: 24px;\n  height: 35px;\n  z-index: 100;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.close-btn {\n  position: absolute;\n  top: 8px;\n  right: 32px;\n  height: 35px;\n  --border-radius: 10px;\n  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);\n  --background-hover: var(--ion-color-dark);\n  --background-hover-opacity: 0.8;\n  z-index: 100;\n  ion-label {\n    display: inline-block;\n    vertical-align: middle;\n    font-size: 14px;\n    font-weight: normal;\n    text-transform: none;\n  }\n  fa-icon {\n    font-size: 16px;\n    padding-right: 12px;\n  }\n}\n\n.loading-spinner {\n  position: absolute;\n  top: calc(50% - 20px);\n  left: calc(50% - 20px);\n  width: 40px;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
