// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  display: flex !important;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.window-title {
  flex: 0 0 auto;
  color: var(--ion-color-primary);
  font-size: 16px;
  font-weight: 600;
  padding-right: 6px;
  padding-bottom: 6px;
  cursor: default;
  border-bottom: 1px solid var(--ion-color-medium);
  margin-bottom: 12px;
}

.window-content {
  color: var(--ion-color-primary);
  font-size: 16px;
  white-space: pre-line;
  margin-bottom: 12px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-components/error-window/error-window.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,wBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,gDAAA;EACA,mBAAA;AACF;;AAEA;EACE,+BAAA;EACA,eAAA;EACA,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF","sourcesContent":[".content-wrapper {\n  display: flex !important;\n  flex-flow: column nowrap;\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n  padding: 16px;\n}\n\n.window-title {\n  flex: 0 0 auto;\n  color: var(--ion-color-primary);\n  font-size: 16px;\n  font-weight: 600;\n  padding-right: 6px;\n  padding-bottom: 6px;\n  cursor: default;\n  border-bottom: 1px solid var(--ion-color-medium);\n  margin-bottom: 12px;\n}\n\n.window-content {\n  color: var(--ion-color-primary);\n  font-size: 16px;\n  white-space: pre-line;\n  margin-bottom: 12px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
