import { LogLevel, LogOutput } from "src/app/models/ui.model";
import { AppMode } from "src/app/models/common";
import { MockOCRType } from "src/app/services/mock-data.service";

/** @ignore */
export const environment = {
  app_mode: AppMode.NORMAL,
  production: false,
  icc_client: "https://inkcapture-test.exon.cz",
  app_version: require("../../package.json").version + "-test",
  icws_server: "https://inkcapture-ws-proxy-test.amitia-ai.com:443",
  smartemailing_url_cs:
    "https://app.smartemailing.cz/public/web-forms-v2/display-form/16747-n2itvivg5lmhuvslbge7zo3ifvgt0otxehza6z1yxhw6e045iy9yn0y1twzfyxfbxb7drlxzd22qot9xpgnjemes0hphhemhf2sa",
  smartemailing_url_en:
    "https://app.smartemailing.cz/public/web-forms-v2/display-form/16747-3am5h2472ub4kielnobdyem37mrkx3ll6xagfzh1novbul45i0v8xbplzgnn5t9c18p2iyo7e6sa6w00v37ofnu6t9y60kxvq3s0",
  smartemailing_msg_field: "fields[cf_12]",
  google_analytics_id: "G-130B9LDHRR",
  mock_data: false,
  mock_ocr_type: MockOCRType.JSON_COCO,
  log_level_local: LogLevel.All,
  log_output_local: LogOutput.Console,
  log_level_server: LogLevel.Warn,
  iam_client: "https://exon-iam-test.amitia-ai.com",
  iam_server: "https://exon-iam-be-test.amitia-ai.com",
  allowed_domains: ["exon-iam-be-test.amitia-ai.com"],
  // iam_server: "https://iam-testing-g4uxkfjhjq-ew.a.run.app",
  // allowed_domains: ["iam-testing-g4uxkfjhjq-ew.a.run.app"],
  anonymous_user_token:
    "eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJleHAiOjE4OTM0NTYwMDAsImlhdCI6MTU3NzgzNjgwMCwiaXNfZXhvbl9hZG1pbiI6ZmFsc2UsImxpY2VuY2VfaWQiOiI2MzE1ZDQzZmVmYjJjNDc1MzFhZjA3OGEiLCJsaWNlbnNlX2lkIjoiNjMxNWQ0M2ZlZmIyYzQ3NTMxYWYwNzhhIiwibmFtZSI6IklDQyBFeG9uIiwicmVsZWFzZV9pZCI6IjYwYmU0ZGVmMzJkZGFkMjVkMmZmNjE2YSIsInNlY3JldF90b2tlbiI6IlRPRE8iLCJzdWIiOiJwdWJsaWMucmVhZGVyQGlua2NhcHR1cmUuY29tIiwidXNlcl9pZCI6IjYzMTVkNDNmZWZiMmM0NzUzMWFmMDc4YiJ9.c_AYC39TAueOBM8KZTCMntImZ6cP4c1PeaXIzqKGDKOf0P3eROwqSMoSGB41PZYlPYErAiG_H07N9m4sTaZ_Aw",
  override_login_token: "",
  restricted_functions_whitelist: [],
  // restricted_functions_whitelist: [
  //   "jan.valenta@exon.cz",
  //   "miroslav.sima@exon.cz",
  //   "petr.polansky@exon.cz",
  //   "petra.kolegarova@exon.cz",
  //   "oldriska.irlbekova@exon.cz",
  //   "oldriska.irlbekova@seznam.cz",
  //   "radek.chramosta@exon.cz",
  //   "miroslav.sima@exon.cz",
  //   "simona.vasicova@exon.cz",
  //   "public.reader@inkcapture.com",
  // ],
};
