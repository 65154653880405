// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  padding: 0px;
  margin: 0px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-components/side-panel/side-panel-container/side-panel-container.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,qBAAA;AACF","sourcesContent":[":host {\r\n  position: absolute;\r\n  padding: 0px;\r\n  margin: 0px;\r\n  width: 100%;\r\n  display: flex;\r\n  flex-flow: row nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
