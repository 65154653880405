/**
 * @fileoverview gRPC-Web generated client stub for InkCapture
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.6
// source: icws_proto/icws_api_gateway/searching.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as icws_proto_icws_api_gateway_searching_find_text_pb from '../../icws_proto/icws_api_gateway/searching/find_text_pb';
import * as icws_proto_icws_api_gateway_searching_find_word_pb from '../../icws_proto/icws_api_gateway/searching/find_word_pb';


export class SearchingClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorFindText = new grpcWeb.MethodDescriptor(
    '/InkCapture.Searching/FindText',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest,
    icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse,
    (request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse.deserializeBinary
  );

  findText(
    request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse>;

  findText(
    request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse>;

  findText(
    request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Searching/FindText',
        request,
        metadata || {},
        this.methodDescriptorFindText,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Searching/FindText',
    request,
    metadata || {},
    this.methodDescriptorFindText);
  }

  methodDescriptorFindWord = new grpcWeb.MethodDescriptor(
    '/InkCapture.Searching/FindWord',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_searching_find_word_pb.FindWordRequest,
    icws_proto_icws_api_gateway_searching_find_word_pb.FindWordResponse,
    (request: icws_proto_icws_api_gateway_searching_find_word_pb.FindWordRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_searching_find_word_pb.FindWordResponse.deserializeBinary
  );

  findWord(
    request: icws_proto_icws_api_gateway_searching_find_word_pb.FindWordRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_searching_find_word_pb.FindWordResponse>;

  findWord(
    request: icws_proto_icws_api_gateway_searching_find_word_pb.FindWordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_searching_find_word_pb.FindWordResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_searching_find_word_pb.FindWordResponse>;

  findWord(
    request: icws_proto_icws_api_gateway_searching_find_word_pb.FindWordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_searching_find_word_pb.FindWordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Searching/FindWord',
        request,
        metadata || {},
        this.methodDescriptorFindWord,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Searching/FindWord',
    request,
    metadata || {},
    this.methodDescriptorFindWord);
  }

}

