import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { LoggingService } from "./log.service";

import { NavigationEnd, Router } from "@angular/router";

/** @ignore */
const TAG = "GoogleAnalyticsService";

declare var gtag: Function;

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  serviceInitialized: boolean = false;

  constructor(private logService: LoggingService, private router: Router) {}

  public init() {
    if (!this.serviceInitialized) {
      this.listenForRouteChanges();
      try {
        const script1 = document.createElement("script");
        script1.async = true;
        script1.src =
          "https://www.googletagmanager.com/gtag/js?id=" + environment.google_analytics_id;
        document.head.appendChild(script1);

        const script2 = document.createElement("script");
        script2.innerHTML =
          `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` +
          environment.google_analytics_id +
          `', {'send_page_view': false });
      `;
        document.head.appendChild(script2);
        this.logService.info(TAG, "Google Analytics ready.");
        this.serviceInitialized = true;
        setTimeout(() => this.googleHit(this.router.url), 2000);
      } catch (error) {
        this.logService.error(TAG, "Error adding Google Analytics script.", error);
      }
    }
  }

  private listenForRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleHit(event.urlAfterRedirects);
      }
    });
  }

  googleHit(path: string) {
    let page_title = path.split("/")[1];
    page_title = page_title ? page_title : "/";
    gtag("event", "page_view", {
      page_title: page_title,
      page_location: environment.icc_client + path,
    });
    this.logService.debug(
      TAG,
      "Google Analytics hit for path_title " +
        page_title +
        " (" +
        environment.icc_client +
        path +
        ")"
    );
  }
}
