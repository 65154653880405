import { animate, style, transition, trigger } from "@angular/animations";
import { LOCALE_ID, Inject, OnInit, OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { AlertController, ModalController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AppMode } from "src/app/models/common";
import {
  ContentType,
  IccDocument,
  IccProcessingParams,
  IccTask,
  PageOcrState,
} from "src/app/models/icc-content.model";
import { TermsAndConditionsComponent } from "src/app/pages/main/header/user-menu/terms-and-conditions/terms-and-conditions.component";
import {
  ProcessedScanStateType,
  TaskStateType,
} from "src/app/proto/generated/icws_proto/icws_api_gateway/types_pb";
import { ContentLoadService, LoadMode } from "src/app/services/content-load.service";
import { GlobalService } from "src/app/services/global.service";
import { IamService } from "src/app/services/iam.service";
import { LoggingService } from "src/app/services/log.service";
import {
  ProcessingParamsId,
  ProcessingParamsService,
} from "src/app/services/processing-params.service";
import { ProcessingService } from "src/app/services/processing.service";

/** @ignore */
const TAG = "OcrPopup";

@Component({
  selector: "app-run-ocr-popup",
  templateUrl: "./run-ocr-popup.component.html",
  styleUrls: ["./run-ocr-popup.component.scss"],
  animations: [
    trigger("ocrParamsAnimation", [
      transition(":enter", [
        style({ height: "0px" }),
        animate("0.3s ease-out", style({ height: "80px" })),
      ]),
      transition(":leave", [
        style({ height: "80px" }),
        animate("0.3s ease-in", style({ height: "0px" })),
      ]),
    ]),
  ],
})
export class RunOcrPopupComponent implements OnInit, OnDestroy {
  documentId: string;
  documentName: string;
  documentPageCount: number;
  ocrSelectedParams: string;
  ocrAvailableParams: IccProcessingParams[];
  docOcrTasks: IccTask[];
  showOcrOptions: boolean = true;
  pageDetailText: string = "";
  private updateOcrStateSubscription: Subscription;
  termsAccepted: boolean = false;
  credit: number = 0;

  pageStateCount: Map<PageOcrState, number> = new Map();

  /** @ignore Just to pass the enum to the template. */
  readonly taskStateType = TaskStateType;
  /** @ignore Just to pass the enum to the template. */
  readonly processingParamsId = ProcessingParamsId;

  private ocrAvailableParamsDesc: { id: string; label: string; description: string }[];

  ocrLogResult: Array<{
    key: TaskStateType;
    label: string;
  }> = [
    {
      key: TaskStateType.TASK_STATE_TYPE_UNKNOWN,
      label: "runOcr.taskStateUnknownLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_NEW,
      label: "runOcr.taskStateNewLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_IN_PROGRESS,
      label: "runOcr.taskStateInProgLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_PAUSED,
      label: "runOcr.taskStatePausedLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_FINISHED_OK,
      label: "runOcr.taskStateFinOkLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_FINISHED_ERR,
      label: "runOcr.taskStateFinErrLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_ABORTED,
      label: "runOcr.taskStateAbortedLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_CANCELLED,
      label: "runOcr.taskStateCancelledLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_FINISHED_NO_PROCESSING,
      label: "runOcr.taskStateFinNoProcLabel",
    },
  ];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private contentloadService: ContentLoadService,
    private modalController: ModalController,
    private toastController: ToastController,
    private alertController: AlertController,
    private processingService: ProcessingService,
    private processingParamsService: ProcessingParamsService,
    private logService: LoggingService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private iamService: IamService
  ) {
    this.ocrAvailableParams = this.processingParamsService.getProcessingParams();
    this.ocrAvailableParamsDesc = this.processingParamsService.getProcessingParamsDetail();
    this.ocrSelectedParams = ProcessingParamsId.CZECH_LM_2;
  }

  ngOnInit() {
    this.contentloadService
      .getNode(this.documentId, LoadMode.LOAD_WHEN_NEEDED, ContentType.DOCUMENT)
      .then((doc) => {
        this.documentName = doc.name;
        this.documentPageCount = (<IccDocument>doc).scans_num;
        this.pageStateCount = this.getPageStateMap(<IccDocument>doc);
      });
    this.processingService.getDocumentOcrStateInfo(this.documentId).then((ocrTasks) => {
      this.docOcrTasks = ocrTasks.taskList;
      if (this.docOcrTasks.length > 0) {
        this.showOcrOptions = false;
      } else {
        this.showOcrOptions = true;
      }
    });

    this.updateOcrStateSubscription = this.processingService.docOcrStateChange.subscribe(
      (updateDocumentId) => {
        if (this.documentId == updateDocumentId) {
          this.processingService.getDocumentOcrStateInfo(this.documentId).then((ocrTasks) => {
            if (this.checkLastTaskChange(this.docOcrTasks, ocrTasks.taskList)) {
              this.contentloadService.invalidateNodes([this.documentId]);
              this.contentloadService
                .getNode(this.documentId, LoadMode.FORCE_LOAD, ContentType.DOCUMENT)
                .then((doc) => {
                  this.documentName = doc.name;
                  this.documentPageCount = (<IccDocument>doc).scans_num;
                  this.pageStateCount = this.getPageStateMap(<IccDocument>doc);
                });
            }
            this.docOcrTasks = ocrTasks.taskList;
          });
        }
      }
    );

    this.iamService.getAccountState().then((account) => {
      this.credit = account.availableBudget;
    });
  }

  /** Unsubscribe from document ocr state refresh subscription */
  ngOnDestroy() {
    if (this.updateOcrStateSubscription) {
      this.updateOcrStateSubscription.unsubscribe();
    }
  }

  checkLastTaskChange(prevTasks: IccTask[], currTasks: IccTask[]) {
    const prevLastState = this.getLastTaskState(prevTasks);
    const currLastState = this.getLastTaskState(currTasks);
    if (prevLastState !== currLastState) return true;
    else return false;
  }

  getLastTaskState(tasks: IccTask[]): TaskStateType {
    if (tasks.length == 0) return TaskStateType.TASK_STATE_TYPE_UNKNOWN;
    let lastTaskstate: TaskStateType = tasks[0].state;
    let lastTaskCreated: Date = tasks[0].created_at;
    for (let task of tasks) {
      if (task.created_at > lastTaskCreated) {
        lastTaskstate = task.state;
        lastTaskCreated = task.created_at;
      }
    }
    return lastTaskstate;
  }

  getPageStateMap(document: IccDocument): Map<PageOcrState, number> {
    let pageStates: Map<PageOcrState, number> = new Map();

    for (let page of document.children) {
      const counter = pageStates.get(page.pageState) || 0;
      pageStates.set(page.pageState, counter + 1);
    }
    return pageStates;
  }

  getPageStateSummary(pageCount: number, pageStates: Map<PageOcrState, number>): string {
    if (pageStates.get(PageOcrState.FINISHED_OK) === pageCount) {
      return this.translate.instant("runOcr.pageStateSumAllProc");
    } else if (pageStates.get(PageOcrState.NOT_STARTED) === pageCount) {
      return null;
    } else if (pageStates.get(PageOcrState.IN_PROGRESS) > 0) {
      return this.translate.instant("runOcr.pageStateSumInProgr");
    } else if (pageStates.get(PageOcrState.FINISHED_ERR) > 0) {
      return this.translate.instant("runOcr.pageStateSumErr");
    } else if (pageStates.get(PageOcrState.NOT_STARTED) > 0) {
      return this.translate.instant("runOcr.pageStateSumSomeUnproc", {
        pageCount: pageStates.get(PageOcrState.NOT_STARTED),
      });
    }
    return null;
  }

  getOcrTaskStateLabel(ocrTaskState: TaskStateType): string {
    return this.ocrLogResult.find((e) => e.key === ocrTaskState).label;
  }

  saveParamsSettings(params: any) {
    this.ocrSelectedParams = params.detail.value;
  }

  toggleShowOcrOptions() {
    this.showOcrOptions = !this.showOcrOptions;
  }

  async onRunOcrTask() {
    if (!this.termsAccepted) {
      return;
    }
    if (this.globalService.currentAppMode() === AppMode.NORMAL) {
      const procParams = this.ocrAvailableParams.find((item) => item.id === this.ocrSelectedParams);

      if (procParams) {
        const additonalInfo = this.checkAdditionalInfo(this.pageStateCount);
        if (additonalInfo) {
          const alert = await this.alertController.create({
            cssClass: "alert-wide",
            backdropDismiss: false,
            message: additonalInfo,
            buttons: [
              {
                text: this.translate.instant("runOcr.procInfoBack"),
                handler: () => {
                  alert.dismiss();
                  this.termsAccepted = false;
                },
              },

              {
                text: this.translate.instant("runOcr.procInfoCont"),
                handler: () => {
                  alert.dismiss();
                  this.runOcr(procParams);
                },
              },
            ],
          });
          await alert.present();
        } else {
          this.runOcr(procParams);
        }
      } else {
        const toast = await this.toastController.create({
          message: this.translate.instant("runOcr.ocrParamsNotFound"),
          position: "top",
          cssClass: "toast",
          duration: 4000,
        });
        toast.present();
      }
    } else {
      const toast = await this.toastController.create({
        message: this.translate.instant("runOcr.ocrDemoToast"),
        position: "top",
        cssClass: "toast",
        duration: 4000,
      });
      toast.present();
    }
  }

  checkAdditionalInfo(pageStateMap: Map<PageOcrState, number>): string {
    if (pageStateMap.get(PageOcrState.FINISHED_OK) > 0) {
      // at least one processed page exists
      return this.translate.instant("runOcr.procInfoText");
    }
    // TODO: Any other situations that could be described in more detail to the user
    return null;
  }

  private runOcr(procParams: IccProcessingParams) {
    this.processingService
      .startDocProcessing(this.documentId, procParams)
      .then(async (response) => {
        this.logService.info(
          TAG,
          'New ocr task for document "' + this.documentId + '" has been started.'
        );
        this.showOcrOptions = false;
        this.processingService.SetDocumentWithPagesInProgressInOcrStateCache(this.documentId);
        this.docOcrTasks = (
          await this.processingService.getDocumentOcrStateInfo(this.documentId, true)
        ).taskList;
        this.termsAccepted = false;
      });
  }

  onCancelOcrTask(taskId: string) {
    this.processingService.cancelTaskProcessing(taskId).then(async (_) => {
      this.logService.info(TAG, 'Ocr task "' + taskId + '" has been cancelled.');
      this.docOcrTasks = (
        await this.processingService.getDocumentOcrStateInfo(this.documentId)
      ).taskList;
    });
  }

  async onDismiss(result: string) {
    if (this.updateOcrStateSubscription) {
      this.updateOcrStateSubscription.unsubscribe();
    }
    await this.modalController.dismiss(result);
  }

  getOcrParamsDetail(paramsId: string): { id: string; label: string; description: string } {
    const params = this.ocrAvailableParamsDesc.find((item) => item.id === paramsId);
    return params;
  }

  sortTasks(tasks: IccTask[]): IccTask[] {
    return tasks.sort((a, b) =>
      a.created_at < b.created_at ? 1 : a.created_at === b.created_at ? 0 : -1
    );
  }

  pageNumText(num: number): string {
    if (num) {
      if (num === 1) {
        return num + " " + this.translate.instant("runOcr.taskDet1Page");
      } else if (num >= 2 && num <= 4) {
        return num + " " + this.translate.instant("runOcr.tasDet2Pages");
      } else if (num >= 5) {
        return num + " " + this.translate.instant("runOcr.taskDet5Pages");
      }
    } else {
      return "";
    }
  }

  taskTooltip(task: IccTask): string {
    let tooltip = "";
    const okCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_OK
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_OK]
      : 0;
    const errCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_ERR
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_ERR]
      : 0;
    const inProgressCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_IN_PROGRESS
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_IN_PROGRESS]
      : 0;
    const canceledCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_CANCELLED
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_CANCELLED]
      : 0;
    const abortedCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_ABORTED
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_ABORTED]
      : 0;

    if (Object.keys(task.pageNumFinished).length === 0) {
      tooltip = this.translate.instant("runOcr.taskDetNothing");
    } else {
      if (okCount > 0) {
        tooltip += this.translate.instant("runOcr.taksDetWithoutErr", { pageCount: okCount });
      }
      if (errCount > 0) {
        tooltip += this.translate.instant("runOcr.taksDetWitErr", { pageCount: errCount });
      }
      if (inProgressCount > 0) {
        tooltip += this.translate.instant("runOcr.taskDetInProg", { pageCount: inProgressCount });
      }
      if (canceledCount > 0) {
        tooltip += this.translate.instant("runOcr.taskDetCancel", { pageCount: canceledCount });
      }
      if (abortedCount > 0) {
        tooltip += this.translate.instant("runOcr.taskDetAbort", { pageCount: abortedCount });
      }
    }
    return tooltip;
  }

  isSomeTaskInProgress(tasks: IccTask[]): boolean {
    for (let task of tasks) {
      if (task.state === TaskStateType.TASK_STATE_TYPE_IN_PROGRESS) {
        return true;
      }
    }
    return false;
  }

  async onTermsAndConditions() {
    const modal = await this.modalController.create({
      component: TermsAndConditionsComponent,
      cssClass: "large-modal",
    });
    await modal.present();
  }
}
