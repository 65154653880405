import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { AnimationController, IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { LoggingService } from "./services/log.service";
import { SharedService } from "./services/shared.service";
import { SharedComponentsModule } from "./shared-components/shared-components.module";
import { GlobalService } from "./services/global.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FontAwesomeModule, FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { JoyrideModule } from "ngx-joyride";
import { PdfViewerModule } from "ng2-pdf-viewer";
import {
  defaultGdprConsents,
  LocalStorageService,
  STORAGE_KEY_TOKEN,
} from "./services/local-storage.service";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";
import { LanguageService } from "./services/language.service";
import defaultLanguage from "src/assets/i18n/en.json";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

/**
 * This factory ensures that every HTTP request made using HttpClient
 * appends an authorization header with a token of a currently
 * signed in user.
 * @param localStorage LocalStorageService
 * @returns JWT authentication factory.
 */
export function jwtOptionsFactory(localStorage: LocalStorageService) {
  return {
    tokenGetter: (request) => {
      if (request.url.includes("assets")) {
        // No authentication header should be sent for requests to the "assets" folder (local files of the server application)
        // Authentication header causes an error on GCP.
        return null;
      } else {
        return localStorage.get(STORAGE_KEY_TOKEN);
      }
    },
    allowedDomains: environment.allowed_domains,
  };
}

/**
 * This is the first thing that runs when the app is being initialized.
 * It runs before app.component.ts code. It can be used to initialize
 * storage, languages and other important features.
 *
 * Ensure there are no circular dependencies.
 * @param localStorage Local storage service
 * @returns
 */
function initApp(localStorage: LocalStorageService, languageService: LanguageService) {
  return () =>
    new Promise(async (resolve, reject) => {
      // Initialize browser storage
      await localStorage.initStorage();
      // Initialize application language
      await languageService.initialize(defaultLanguage);
      // Set default GDPR consents to enable to store at least the necessary data (like user token)
      localStorage.useGdprConsents(defaultGdprConsents);
      resolve(true);
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      navAnimation: (_) => new AnimationController().create(), // to disable page transition animation
      innerHTMLTemplatesEnabled: true, // to allow custom HTML as string in ion-alert, ion-infinite-scroll-content, ion-loading, ion-refresher-content, and ion-toast
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [LocalStorageService],
      },
    }),
    JoyrideModule.forRoot(),
    PdfViewerModule,
  ],
  providers: [
    GoogleAnalyticsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoggingService,
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: LoggingService,
    },
    SharedService,
    GlobalService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [LocalStorageService, LanguageService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
