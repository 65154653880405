import { Injectable } from "@angular/core";
import { UpdatingClient } from "../proto/generated/icws_proto/icws_api_gateway/UpdatingServiceClientPb";
import { LoggingService } from "./log.service";
import { environment } from "src/environments/environment";
import {
  UpdateProcessedScanRequest,
  UpdateProcessedScanResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/updating/update_processed_scan_pb";
import { SharedService } from "./shared.service";
import { UpdatedProcessedScan } from "../proto/generated/icws_proto/icws_api_gateway/updating/base_pb";
import {
  StructuredBlock,
  StructuredContent,
  StructuredLine,
  StructuredWord,
  WordSplitType,
} from "../models/icc-content.model";
import {
  Block,
  Line,
  Point,
  Position,
  Word,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/base_pb";
import { WordSplitTypeType } from "../proto/generated/icws_proto/icws_api_gateway/storage/storage_types_pb";

const TAG = "IcwsUpdatingService";

@Injectable({
  providedIn: "root",
})
export class IcwsUpdatingService {
  updatingService: UpdatingClient;
  private _authMetadata = { authorization: null };

  constructor(private sharedService: SharedService, private logService: LoggingService) {
    this.updatingService = new UpdatingClient(environment.icws_server, null, null);
  }

  async initMetadata(token: string) {
    if (token !== null) {
      this._authMetadata.authorization = token;
    }
  }

  clearMetadata() {
    this._authMetadata.authorization = null;
  }

  //
  // UPDATE PROCESSED SCAN
  //
  async updateProcessedScan(
    processedScanId: string,
    processedScanUpdatedContent: StructuredContent
  ): Promise<UpdateProcessedScanResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new UpdateProcessedScanRequest();
    let updatedProcessedScan = new UpdatedProcessedScan();

    updatedProcessedScan.setId(processedScanId);
    updatedProcessedScan.setBlocksList(this.convertContent(processedScanUpdatedContent));

    request.setRequestId(reqGUID);
    request.setProcessedScan(updatedProcessedScan);

    return this.sharedService.retryPromise(
      () => this.updatingService.updateProcessedScan(request, this._authMetadata),
      "updateProcessedScan()",
      { processedScanId: processedScanId }
    );
  }

  private convertContent(iccContent: StructuredContent): Block[] {
    let icwsBlocks: Block[] = [];
    for (let b = 0; b < iccContent.blocks.length; b++) {
      let icwsLines: Line[] = [];
      for (let l = 0; l < iccContent.blocks[b].lines.length; l++) {
        let icwsWords: Word[] = [];
        for (let w = 0; w < iccContent.blocks[b].lines[l].words.length; w++) {
          icwsWords.push(this.createIcwsWord(iccContent.blocks[b].lines[l].words[w], w));
        }
        icwsLines.push(this.createIcwsLine(iccContent.blocks[b].lines[l], l, icwsWords));
      }
      icwsBlocks.push(this.createIcwsBlock(iccContent.blocks[b], b, icwsLines));
    }
    return icwsBlocks;
  }

  private createIcwsWord(word: StructuredWord, order: number): Word {
    const icwsWord: Word = new Word();
    icwsWord.setId(word.guid);
    const pos: Position = new Position();
    pos.setTopLeft(new Point().setX(word.boundingBox.a.x).setY(word.boundingBox.a.y));
    pos.setTopRight(new Point().setX(word.boundingBox.b.x).setY(word.boundingBox.b.y));
    pos.setBottomRight(new Point().setX(word.boundingBox.c.x).setY(word.boundingBox.c.y));
    pos.setBottomLeft(new Point().setX(word.boundingBox.d.x).setY(word.boundingBox.d.y));
    icwsWord.setPosition(pos);
    icwsWord.setOrder(order);
    icwsWord.setText(word.content);
    icwsWord.setConfidence(word.wordCertainty);
    icwsWord.setConfidenceCharsList(word.charsCertainty);
    icwsWord.setSplitType(this.convertSplitType(word.splitType));
    icwsWord.setOtherPartId(word.otherPartId);
    icwsWord.setOtherPartText(word.otherPartText);
    return icwsWord;
  }

  private createIcwsLine(line: StructuredLine, order: number, icwsWords: Word[]): Line {
    const icwsLine: Line = new Line();
    icwsLine.setId(line.guid);
    const pos: Position = new Position();
    pos.setTopLeft(new Point().setX(line.boundingBox.a.x).setY(line.boundingBox.a.y));
    pos.setTopRight(new Point().setX(line.boundingBox.b.x).setY(line.boundingBox.b.y));
    pos.setBottomRight(new Point().setX(line.boundingBox.c.x).setY(line.boundingBox.c.y));
    pos.setBottomLeft(new Point().setX(line.boundingBox.d.x).setY(line.boundingBox.d.y));
    icwsLine.setPosition(pos);
    icwsLine.setOrder(order);
    icwsLine.setWordsList(icwsWords);
    return icwsLine;
  }

  private createIcwsBlock(block: StructuredBlock, order: number, icwsLines: Line[]): Block {
    const icwsBlock: Block = new Block();
    icwsBlock.setId(block.guid);
    const pos: Position = new Position();
    pos.setTopLeft(new Point().setX(block.boundingBox.a.x).setY(block.boundingBox.a.y));
    pos.setTopRight(new Point().setX(block.boundingBox.b.x).setY(block.boundingBox.b.y));
    pos.setBottomRight(new Point().setX(block.boundingBox.c.x).setY(block.boundingBox.c.y));
    pos.setBottomLeft(new Point().setX(block.boundingBox.d.x).setY(block.boundingBox.d.y));
    icwsBlock.setPosition(pos);
    icwsBlock.setLinesList(icwsLines);
    icwsBlock.setType(block.type);
    // icwsBlock.setText(null);     // Text property will be be recalculated by ICWS
    return icwsBlock;
  }

  private convertSplitType(iccSplitType: WordSplitType): WordSplitTypeType {
    switch (iccSplitType) {
      case WordSplitType.FIRST_PART:
        return WordSplitTypeType.FIRST_PART_SPLIT;
      case WordSplitType.SECOND_PART:
        return WordSplitTypeType.SECOND_PART_SPLIT;
      case WordSplitType.NOT_SPLITTED:
        return WordSplitTypeType.NOT_SPLIT;
      default:
        return WordSplitTypeType.UNKNOWN_WORD_SPLIT;
    }
  }
}
