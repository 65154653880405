import { DatePipe } from "@angular/common";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { LogLevel, LogOutput } from "../models/ui.model";
import { environment } from "src/environments/environment";

import { ErrorWindowComponent } from "../shared-components/error-window/error-window.component";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { IcwsLoggingService } from "./icws-logging.service";
import { LogLevelType } from "../proto/generated/icws_proto/icws_api_gateway/types_pb";

@Injectable({
  providedIn: "root",
})
export class LoggingService implements ErrorHandler {
  logWithDate: boolean = false;

  constructor(
    private zone: NgZone,
    private injector: Injector,
    private translate: TranslateService,
    private icwsLogging: IcwsLoggingService
  ) {}

  debug(tag: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(tag, msg, LogLevel.Debug, optionalParams);
  }

  info(tag: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(tag, msg, LogLevel.Info, optionalParams);
  }

  warn(tag: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(tag, msg, LogLevel.Warn, optionalParams);
  }

  error(tag: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(tag, msg, LogLevel.Error, optionalParams);
    const classifiedError = this.classifyError(optionalParams[0]);
    // Only classified errors should be displayed to the user
    if (classifiedError.class != IccErrorClass.ERR_UNKNOWN) {
      this.showKnownError(classifiedError);
    }
  }

  log(tag: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(tag, msg, LogLevel.Info, optionalParams);
  }

  /** Manages all unhandled errors in the application. */
  handleError(error: any) {
    this.writeToLog("Unhandled exception", error.message, LogLevel.Error, [error]);
  }

  private writeToLog(tag: string, msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.tag = tag;
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;
      if (environment.log_output_local == LogOutput.Console) {
        this.consoleOutput(entry);
      } else {
        console.log(entry.buildLogString(environment.log_output_local));
        // TODO: Support log to file
      }
      this.sendLogToIcwsServer(entry);
    }
  }

  private sendLogToIcwsServer(logEntry: LogEntry) {
    if (
      logEntry.level >= environment.log_level_server ||
      environment.log_level_server === LogLevel.All
    ) {
      let icwsLogLevel: LogLevelType;
      switch (logEntry.level) {
        case LogLevel.Debug:
          icwsLogLevel = LogLevelType.LOG_LEVEL_TYPE_DEBUG;
          break;
        case LogLevel.Info:
          icwsLogLevel = LogLevelType.LOG_LEVEL_TYPE_INFO;
          break;
        case LogLevel.Warn:
          icwsLogLevel = LogLevelType.LOG_LEVEL_TYPE_WARNING;
          break;
        case LogLevel.Error:
          icwsLogLevel = LogLevelType.LOG_LEVEL_TYPE_ERROR;
          break;
        default:
          icwsLogLevel = LogLevelType.LOG_LEVEL_TYPE_NOT_SET;
      }
      this.icwsLogging.log(icwsLogLevel, logEntry.buildLogString(LogOutput.File));
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if (level >= environment.log_level_local || environment.log_level_local === LogLevel.All) {
      ret = true;
    }
    return ret;
  }

  private consoleOutput(entry: LogEntry) {
    switch (entry.level) {
      case LogLevel.Debug:
        if (entry.extraInfo.length > 0)
          console.info(entry.buildLogString(LogOutput.Console), entry.extraInfo);
        else console.info(entry.buildLogString(LogOutput.Console));
        break;
      case LogLevel.Info:
        if (entry.extraInfo.length > 0)
          console.info(entry.buildLogString(LogOutput.Console), entry.extraInfo);
        else console.info(entry.buildLogString(LogOutput.Console));
        break;
      case LogLevel.Warn:
        if (entry.extraInfo.length > 0)
          console.warn(entry.buildLogString(LogOutput.Console), entry.extraInfo);
        else console.warn(entry.buildLogString(LogOutput.Console));
        break;
      case LogLevel.Error:
        if (entry.extraInfo.length > 0)
          console.error(entry.buildLogString(LogOutput.Console), entry.extraInfo);
        else console.error(entry.buildLogString(LogOutput.Console));
        break;
      default:
        if (entry.extraInfo.length > 0)
          console.log(entry.buildLogString(LogOutput.Console), entry.extraInfo);
        else console.log(entry.buildLogString(LogOutput.Console));
    }
  }

  private classifyError(err: any): ClassifiedError {
    switch (err.code) {
      case 7:
        return {
          class: IccErrorClass.ERR_AUTH,
          userText: this.translate.instant("loggingService.authError"),
        };
      case 8:
        return {
          class: IccErrorClass.ERR_QUOTA,
          userText: this.translate.instant("loggingService.quotaError"),
        };
      default:
        return {
          class: IccErrorClass.ERR_UNKNOWN,
          userText: this.translate.instant("loggingService.unknownError"),
        };
    }
  }

  private showKnownError(error: ClassifiedError) {
    this.zone.run(async () => {
      const modalController = this.injector.get<ModalController>(ModalController);
      const modal = await modalController.create({
        component: ErrorWindowComponent,
        cssClass: "auto-height",
        backdropDismiss: false,
        componentProps: {
          errorText: error.userText,
        },
      });
      await modal.present();
    });
  }
}

export class LogEntry {
  entryDate: Date = new Date();
  tag: string = "";
  message: string = "";
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;

  buildLogString(outputType: LogOutput): string {
    let ret: string = "";

    if (this.logWithDate) {
      let pipe = new DatePipe("en-US");
      ret = pipe.transform(Date.now(), "dd.MM.yyyy,HH:mm:ss") + " ";
    }
    ret += this.tag;
    ret += "(" + LogLevel[this.level].charAt(0) + "): ";
    ret += this.message;
    if (this.extraInfo.length && outputType != LogOutput.Console) {
      ret += ", " + this.formatParams(this.extraInfo);
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(", ");

    try {
      // Is there at least one object in the array?
      if (params.some((p) => typeof p == "object")) {
        ret = "";

        // Build comma-delimited string
        for (let item of params) {
          ret += JSON.stringify(item) + ", ";
        }
      }
      return ret;
    } catch (e) {
      return null;
    }
  }
}

interface ClassifiedError {
  class: IccErrorClass;
  userText: string;
}

export enum IccErrorClass {
  ERR_UNKNOWN = 0,
  ERR_AUTH = 7,
  ERR_QUOTA = 8,
}
