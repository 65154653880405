import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IccProcessingParams } from "../models/icc-content.model";
import { ProcessingParams } from "../proto/generated/icws_proto/icws_api_gateway/processing/base_pb";
import {
  GetTaskProgressRequest,
  GetTaskProgressResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/processing/get_task_progress_pb";
import {
  StartProcessingRequest,
  StartProcessingResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/processing/start_processing_pb";
import {
  CancelTaskProcessingRequest,
  CancelTaskProcessingResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/processing/cancel_task_processing_pb";
import { ProcessingClient } from "../proto/generated/icws_proto/icws_api_gateway/ProcessingServiceClientPb";
import { SharedService } from "./shared.service";
import { Struct } from "google-protobuf/google/protobuf/struct_pb";

const TAG = "IcwsProcessingService";

@Injectable({
  providedIn: "root",
})
export class IcwsProcessingService {
  processingService: ProcessingClient;
  private _authMetadata = { authorization: null };

  constructor(private sharedService: SharedService) {
    this.processingService = new ProcessingClient(environment.icws_server, null, null);
  }

  async initMetadata(token: string) {
    if (token !== null) {
      this._authMetadata.authorization = token;
    }
  }

  clearMetadata() {
    this._authMetadata.authorization = null;
  }

  startProcessing(
    documentId: string,
    iccParams: IccProcessingParams
  ): Promise<StartProcessingResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new StartProcessingRequest();
    request.setRequestId(reqGUID);
    request.setParams(this.setParams(iccParams));
    request.setDocumentId(documentId);
    return this.sharedService.retryPromise(
      () => this.processingService.startProcessing(request, this._authMetadata),
      "processingService()",
      { documentId: documentId, iccParams: iccParams }
    );
  }

  private setParams(iccParams: IccProcessingParams): ProcessingParams {
    const param = new ProcessingParams();
    param.setVersion(iccParams.version);
    if (iccParams.icws_seg) {
      param.setIcwsSeg(Struct.fromJavaScript(JSON.parse(JSON.stringify(iccParams.icws_seg))));
    }
    if (iccParams.icws_lm) {
      param.setIcwsLm(Struct.fromJavaScript(JSON.parse(JSON.stringify(iccParams.icws_lm))));
    }
    return param;
  }

  cancelTaskProcessing(taskId: string): Promise<CancelTaskProcessingResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new CancelTaskProcessingRequest();
    request.setRequestId(reqGUID);
    request.setTaskId(taskId);
    return this.sharedService.retryPromise(
      () => this.processingService.cancelTaskProcessing(request, this._authMetadata),
      "cancelTaskProcessing()",
      { taskId: taskId }
    );
  }

  GetTaskProgress(taskIds: string[]): Promise<GetTaskProgressResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new GetTaskProgressRequest();
    request.setRequestId(reqGUID);
    request.setTasksIdsList(taskIds);
    return this.sharedService.retryPromise(
      () => this.processingService.getTaskProgress(request, this._authMetadata),
      "getTaskProgress()",
      { taskIds: taskIds }
    );
  }
}
