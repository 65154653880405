/**
 * @fileoverview gRPC-Web generated client stub for InkCapture
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.6
// source: icws_proto/icws_api_gateway/storage.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as icws_proto_icws_api_gateway_storage_add_scan_to_document_pb from '../../icws_proto/icws_api_gateway/storage/add_scan_to_document_pb';
import * as icws_proto_icws_api_gateway_storage_create_document_pb from '../../icws_proto/icws_api_gateway/storage/create_document_pb';
import * as icws_proto_icws_api_gateway_storage_create_folder_pb from '../../icws_proto/icws_api_gateway/storage/create_folder_pb';
import * as icws_proto_icws_api_gateway_storage_delete_documents_pb from '../../icws_proto/icws_api_gateway/storage/delete_documents_pb';
import * as icws_proto_icws_api_gateway_storage_delete_folders_pb from '../../icws_proto/icws_api_gateway/storage/delete_folders_pb';
import * as icws_proto_icws_api_gateway_storage_get_document_pb from '../../icws_proto/icws_api_gateway/storage/get_document_pb';
import * as icws_proto_icws_api_gateway_storage_get_folder_pb from '../../icws_proto/icws_api_gateway/storage/get_folder_pb';
import * as icws_proto_icws_api_gateway_storage_get_processed_scan_pb from '../../icws_proto/icws_api_gateway/storage/get_processed_scan_pb';
import * as icws_proto_icws_api_gateway_storage_get_root_folder_pb from '../../icws_proto/icws_api_gateway/storage/get_root_folder_pb';
import * as icws_proto_icws_api_gateway_storage_get_scan_pb from '../../icws_proto/icws_api_gateway/storage/get_scan_pb';
import * as icws_proto_icws_api_gateway_storage_list_folder_pb from '../../icws_proto/icws_api_gateway/storage/list_folder_pb';
import * as icws_proto_icws_api_gateway_storage_move_documents_pb from '../../icws_proto/icws_api_gateway/storage/move_documents_pb';
import * as icws_proto_icws_api_gateway_storage_move_folders_pb from '../../icws_proto/icws_api_gateway/storage/move_folders_pb';
import * as icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb from '../../icws_proto/icws_api_gateway/storage/remove_scans_from_document_pb';
import * as icws_proto_icws_api_gateway_storage_update_documents_pb from '../../icws_proto/icws_api_gateway/storage/update_documents_pb';
import * as icws_proto_icws_api_gateway_storage_update_folders_pb from '../../icws_proto/icws_api_gateway/storage/update_folders_pb';
import * as icws_proto_icws_api_gateway_storage_update_scans_pb from '../../icws_proto/icws_api_gateway/storage/update_scans_pb';


export class StorageClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateDocument = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/CreateDocument',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentRequest,
    icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentResponse,
    (request: icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentResponse.deserializeBinary
  );

  createDocument(
    request: icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentResponse>;

  createDocument(
    request: icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentResponse>;

  createDocument(
    request: icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_create_document_pb.CreateDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/CreateDocument',
        request,
        metadata || {},
        this.methodDescriptorCreateDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/CreateDocument',
    request,
    metadata || {},
    this.methodDescriptorCreateDocument);
  }

  methodDescriptorGetDocument = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/GetDocument',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentRequest,
    icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentResponse,
    (request: icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentResponse.deserializeBinary
  );

  getDocument(
    request: icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentResponse>;

  getDocument(
    request: icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentResponse>;

  getDocument(
    request: icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_document_pb.GetDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/GetDocument',
        request,
        metadata || {},
        this.methodDescriptorGetDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/GetDocument',
    request,
    metadata || {},
    this.methodDescriptorGetDocument);
  }

  methodDescriptorUpdateDocuments = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/UpdateDocuments',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsRequest,
    icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsResponse,
    (request: icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsResponse.deserializeBinary
  );

  updateDocuments(
    request: icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsResponse>;

  updateDocuments(
    request: icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsResponse>;

  updateDocuments(
    request: icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_update_documents_pb.UpdateDocumentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/UpdateDocuments',
        request,
        metadata || {},
        this.methodDescriptorUpdateDocuments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/UpdateDocuments',
    request,
    metadata || {},
    this.methodDescriptorUpdateDocuments);
  }

  methodDescriptorDeleteDocuments = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/DeleteDocuments',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsRequest,
    icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsResponse,
    (request: icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsResponse.deserializeBinary
  );

  deleteDocuments(
    request: icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsResponse>;

  deleteDocuments(
    request: icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsResponse>;

  deleteDocuments(
    request: icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_delete_documents_pb.DeleteDocumentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/DeleteDocuments',
        request,
        metadata || {},
        this.methodDescriptorDeleteDocuments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/DeleteDocuments',
    request,
    metadata || {},
    this.methodDescriptorDeleteDocuments);
  }

  methodDescriptorMoveDocuments = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/MoveDocuments',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsRequest,
    icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsResponse,
    (request: icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsResponse.deserializeBinary
  );

  moveDocuments(
    request: icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsResponse>;

  moveDocuments(
    request: icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsResponse>;

  moveDocuments(
    request: icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_move_documents_pb.MoveDocumentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/MoveDocuments',
        request,
        metadata || {},
        this.methodDescriptorMoveDocuments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/MoveDocuments',
    request,
    metadata || {},
    this.methodDescriptorMoveDocuments);
  }

  methodDescriptorGetScan = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/GetScan',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanRequest,
    icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanResponse,
    (request: icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanResponse.deserializeBinary
  );

  getScan(
    request: icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanResponse>;

  getScan(
    request: icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanResponse>;

  getScan(
    request: icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_scan_pb.GetScanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/GetScan',
        request,
        metadata || {},
        this.methodDescriptorGetScan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/GetScan',
    request,
    metadata || {},
    this.methodDescriptorGetScan);
  }

  methodDescriptorAddScanToDocument = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/AddScanToDocument',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentRequest,
    icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentResponse,
    (request: icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentResponse.deserializeBinary
  );

  addScanToDocument(
    request: icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentResponse>;

  addScanToDocument(
    request: icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentResponse>;

  addScanToDocument(
    request: icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_add_scan_to_document_pb.AddScanToDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/AddScanToDocument',
        request,
        metadata || {},
        this.methodDescriptorAddScanToDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/AddScanToDocument',
    request,
    metadata || {},
    this.methodDescriptorAddScanToDocument);
  }

  methodDescriptorRemoveScansFromDocument = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/RemoveScansFromDocument',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentRequest,
    icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentResponse,
    (request: icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentResponse.deserializeBinary
  );

  removeScansFromDocument(
    request: icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentResponse>;

  removeScansFromDocument(
    request: icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentResponse>;

  removeScansFromDocument(
    request: icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_remove_scans_from_document_pb.RemoveScansFromDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/RemoveScansFromDocument',
        request,
        metadata || {},
        this.methodDescriptorRemoveScansFromDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/RemoveScansFromDocument',
    request,
    metadata || {},
    this.methodDescriptorRemoveScansFromDocument);
  }

  methodDescriptorUpdateScans = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/UpdateScans',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansRequest,
    icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansResponse,
    (request: icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansResponse.deserializeBinary
  );

  updateScans(
    request: icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansResponse>;

  updateScans(
    request: icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansResponse>;

  updateScans(
    request: icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_update_scans_pb.UpdateScansResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/UpdateScans',
        request,
        metadata || {},
        this.methodDescriptorUpdateScans,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/UpdateScans',
    request,
    metadata || {},
    this.methodDescriptorUpdateScans);
  }

  methodDescriptorCreateFolder = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/CreateFolder',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderRequest,
    icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderResponse,
    (request: icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderResponse.deserializeBinary
  );

  createFolder(
    request: icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderResponse>;

  createFolder(
    request: icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderResponse>;

  createFolder(
    request: icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_create_folder_pb.CreateFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/CreateFolder',
        request,
        metadata || {},
        this.methodDescriptorCreateFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/CreateFolder',
    request,
    metadata || {},
    this.methodDescriptorCreateFolder);
  }

  methodDescriptorGetFolder = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/GetFolder',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderRequest,
    icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderResponse,
    (request: icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderResponse.deserializeBinary
  );

  getFolder(
    request: icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderResponse>;

  getFolder(
    request: icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderResponse>;

  getFolder(
    request: icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_folder_pb.GetFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/GetFolder',
        request,
        metadata || {},
        this.methodDescriptorGetFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/GetFolder',
    request,
    metadata || {},
    this.methodDescriptorGetFolder);
  }

  methodDescriptorListFolder = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/ListFolder',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderRequest,
    icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderResponse,
    (request: icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderResponse.deserializeBinary
  );

  listFolder(
    request: icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderResponse>;

  listFolder(
    request: icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderResponse>;

  listFolder(
    request: icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_list_folder_pb.ListFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/ListFolder',
        request,
        metadata || {},
        this.methodDescriptorListFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/ListFolder',
    request,
    metadata || {},
    this.methodDescriptorListFolder);
  }

  methodDescriptorUpdateFolders = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/UpdateFolders',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersRequest,
    icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersResponse,
    (request: icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersResponse.deserializeBinary
  );

  updateFolders(
    request: icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersResponse>;

  updateFolders(
    request: icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersResponse>;

  updateFolders(
    request: icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_update_folders_pb.UpdateFoldersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/UpdateFolders',
        request,
        metadata || {},
        this.methodDescriptorUpdateFolders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/UpdateFolders',
    request,
    metadata || {},
    this.methodDescriptorUpdateFolders);
  }

  methodDescriptorDeleteFolders = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/DeleteFolders',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersRequest,
    icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersResponse,
    (request: icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersResponse.deserializeBinary
  );

  deleteFolders(
    request: icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersResponse>;

  deleteFolders(
    request: icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersResponse>;

  deleteFolders(
    request: icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_delete_folders_pb.DeleteFoldersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/DeleteFolders',
        request,
        metadata || {},
        this.methodDescriptorDeleteFolders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/DeleteFolders',
    request,
    metadata || {},
    this.methodDescriptorDeleteFolders);
  }

  methodDescriptorMoveFolders = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/MoveFolders',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersRequest,
    icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersResponse,
    (request: icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersResponse.deserializeBinary
  );

  moveFolders(
    request: icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersResponse>;

  moveFolders(
    request: icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersResponse>;

  moveFolders(
    request: icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_move_folders_pb.MoveFoldersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/MoveFolders',
        request,
        metadata || {},
        this.methodDescriptorMoveFolders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/MoveFolders',
    request,
    metadata || {},
    this.methodDescriptorMoveFolders);
  }

  methodDescriptorGetRootFolder = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/GetRootFolder',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderRequest,
    icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderResponse,
    (request: icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderResponse.deserializeBinary
  );

  getRootFolder(
    request: icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderResponse>;

  getRootFolder(
    request: icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderResponse>;

  getRootFolder(
    request: icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_root_folder_pb.GetRootFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/GetRootFolder',
        request,
        metadata || {},
        this.methodDescriptorGetRootFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/GetRootFolder',
    request,
    metadata || {},
    this.methodDescriptorGetRootFolder);
  }

  methodDescriptorGetProcessedScan = new grpcWeb.MethodDescriptor(
    '/InkCapture.Storage/GetProcessedScan',
    grpcWeb.MethodType.UNARY,
    icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanRequest,
    icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanResponse,
    (request: icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanResponse.deserializeBinary
  );

  getProcessedScan(
    request: icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanResponse>;

  getProcessedScan(
    request: icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanResponse>;

  getProcessedScan(
    request: icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: icws_proto_icws_api_gateway_storage_get_processed_scan_pb.GetProcessedScanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Storage/GetProcessedScan',
        request,
        metadata || {},
        this.methodDescriptorGetProcessedScan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Storage/GetProcessedScan',
    request,
    metadata || {},
    this.methodDescriptorGetProcessedScan);
  }

}

